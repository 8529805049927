import React, { useEffect, useState, useRef, useReducer } from "react";
import Footer from "../../../components/Footer.jsx";
import { createGlobalStyle } from "styled-components";
import heartBlack from "../../../../assets/images/heart_black.png";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import heart from "../../../../assets/images/heart-icon.png";
import rlc from "../../../../assets/images/RLF-icon.png";
import {
  Modal,
  InputGroup,
  FormControl,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { PulseLoader, BounceLoader, ScaleLoader } from "react-spinners";
import { toInteger } from "lodash";
import swal from "sweetalert";
import axios from "axios";
import DatePicker from "react-datepicker";
import BuyNftMarketAction from "../../../../Redux/Actions/NftActions/BuyNftMarketActions";
import detectEthereumProvider from "@metamask/detect-provider";
import {
  sendTransection,
  signMessage,
  approveNft,
  buyNftMarket,
  cancelNft,
  openForAuction,
  checkBalance1,
  approveContract,
  acceptBid,
  makeOffer,
  getOfferListing,
  acceptSimpleListingOffer,
  cancelListingOffer,
  getTotalOffersCount,
} from "../../../../metamask";
import Web3 from "web3";
import moment from "moment";
import GetFavouriteNftAction from "../../../../Redux/Actions/NftActions/GetFavouriteNftAction";
import defaultImg from "../../../../assets/images/default.png";
import http from "../../../../Redux/Api/http";
import ReactReadMoreReadLess from "react-read-more-read-less";

import CopyToClipboard from "react-copy-to-clipboard";
import API from "../../../../Redux/Api";
import { PAGE_Login_PATH } from "../../../../Routers/Routes";
import {
  BLACK_COLOR,
  IMAGE_NOT_FOUND,
  LOADER_MEDIUM_SIZE,
  LOADER_MEDIUM_TWENTY_SIZE,
  LOADER_SMALL_SIZE,
  MP4,
  RED_COLOR,
  USER_NFT_DETAILS_TABS,
  WHITE_COLOR,
} from "../../../../constants";
import SimpleOfferAction from "../../../../Redux/Actions/SimpleOffer/SimpleOfferAction";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  GET_MARKET_NFT_ADDRESS,
  GET_NFT_MARKET_BY_IDS,
} from "../../../../Routers/apiRoutes";
import { ethers } from "ethers";
const initialState = { isDisable: false };
const reducer = (state, action) => {
  switch (action.type) {
    case "clicked":
      return { isDisable: true };
    case "notClicked":
      return { isDisable: false };
  }
};
const GlobalStyles = createGlobalStyle`

`;

const UserNftDetails = function () {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;

  const WalletAddress = useSelector(
    (state) => state.WalletConnction?.WalletResponse?.accounts,
  );

  const WalletBalance = useSelector(
    (state) => state.WalletConnction?.WalletResponse?.balance,
  );

  const AuthConnectState = useSelector(
    (state) => state.AuthConnect.AuthConnectResponse?.data,
  );

  let GetFavouriteNft = useSelector(
    (state) => state.GetFavouriteNft?.GetFavouriteNftResponse?.data,
  );

  const isConnected = useSelector(
    (state) => state.Login?.authResponse?.data?.token,
  );

  const [show, setShow] = useState(false);

  const [NewPrice, SetNewPrice] = useState();

  const [selValid, setSelValid] = useState(true);
  const [inputAmount, setInputAmount] = useState();
  const [maxInputAmount, setMaxInputAmount] = useState();

  const [amountCheck, setAmounCheck] = useState(false);
  const [amountCheck1, setAmounCheck1] = useState(false);
  const [balance, setBalance] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [biddingLoading, setIsBiddingLoading] = useState(true);
  const [lastindex, setlastindex] = useState(0);
  const [modalStatus, setModalStatus] = useState(false);
  const [emptyBids, setEmptyBids] = useState(false);
  const [filterTrigger, setFilterTrigger] = useState(false);
  const [MyLiked_nfts, setMyLikedNFt] = useState([]);
  const [biddings, setBiddings] = useState();
  const [myData, setMyData] = useState();

  const [sellnftpriceerror, setSellnftpriceerror] = useState(false);
  const [sellnftpriceerror1, setSellnftpriceerror1] = useState(false);
  const [maxpriceofsell, setmaxpriceofsell] = useState(false);
  const [amountCheck3, setAmounCheck3] = useState(false);
  const [amountcheck6, setAmounCheck6] = useState(false);
  const [amountCheck4, setAmounCheck4] = useState(false);
  const [countoffav, setcountoffav] = useState(0);
  const [buttonclicked, setbuttonclicked] = useState(false);
  const [bidTrigger, setBidtrigger] = useState(false);
  const [isInterval, setIsInterval] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(false);
  const [equalcheck, setequalcheck] = useState(false);
  const [checksaleminus, setchecksaleminus] = useState(false);

  const [checksaleminus1, setchecksaleminus1] = useState(true);
  const [paramsCheck, setParams] = useState();
  const [paramsLoading, setParamsLoading] = useState(true);
  const [imageShow, setImageShow] = useState(false);
  const [timer, setTimer] = useState(false);
  const [bidInProgress, setBidInProgress] = useState(false);
  const [isOfferInProgress, setIsOfferInProgress] = useState(false);
  const [favnft, setfavnft] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [expiryDateplacebid, setexpiryDateplacebid] = useState(new Date());
  const [minndate, setminndate] = useState(new Date());
  const [bidError, setBidError] = useState(false);
  const [bidError1, setBidError1] = useState(false);
  const [bidError2, setBidError2] = useState(false);
  const [expiryError, setExpiryError] = useState(false);
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [myDataLoader, setmyDataLoader] = useState(true);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [isStacked, setIsStacked] = useState(false);
  const [amountCheck2, setAmounCheck2] = useState(false);
  const [stakeLoading, setStakeLoading] = useState(true);
  const [zerosError, setZerosError] = useState(false);
  const [zerosError1, setZerosError1] = useState(false);
  const [lower, setlower] = useState(false);
  const [maxbalance, setmaxbalance] = useState(false);
  const [negetive, setnegetive] = useState(false);

  const [negetive1, setnegetive1] = useState(false);
  const [minbalance, setminbalance] = useState(false);

  const [makeofferbutton, setmakeofferbutton] = useState(false);

  const [state, disableDispatch] = useReducer(reducer, initialState);
  const [deleteNFTModalShow, setDeleteNFTModalShow] = useState(false);
  const [isSellBargain, setIsSellBargain] = useState(false);

  const [giveMakeOfferValueModalShow, setGiveMakeOfferValueModalShow] =
    useState(false);
  const [getOfferListingData, setGetOfferListingData] = useState([]);
  const [arrayLength, setArrayLength] = useState();
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const Token = useSelector((state) => state.Login?.authResponse?.data?.token);

  const sellingModal = () => {
    setFilterTrigger(false);
    setIsSellBargain(false);
  };

  const [details, setDetails] = React.useState(false);
  const [historymenu, setHistoryMenu] = React.useState(true);
  const [aboutmenu, setAbout] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [activeTab, setActiveTab] = useState(
    USER_NFT_DETAILS_TABS.LISTING_OFFER_TAB,
  );
  const handleBtnClick = () => {
    showActivityHistory();
    setOpenMenu(true);
    setOpenMenu1(false);
    document?.getElementById("Mainbtn").classList.add("active");

    document?.getElementById("Mainbtn1").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(true);
    setOpenMenu(false);
    document?.getElementById("Mainbtn1").classList.add("active");
    document?.getElementById("Mainbtn").classList.remove("active");
  };

  const handleClose = () => {
    setShow(false);
    setAmounCheck1(false);
    setAmounCheck(false);
    setInputAmount(null);
  };

  const handleShow = () => {
    if (!isConnected || isConnected === undefined) {
      return history.push("/connectwallet");
    } else {
      setShow(true);
    }
  };

  const handleImageClose = () => setImageShow(false);
  const handleClose1 = () => setOpenBid(false);

  const handleClose2 = () => {
    setOpenBid2(false);
    setMakeOfferPrice("");
    setMakeOfferPriceValidation(false);
  };
  const handleClose3 = () => setOpenBid3(false);

  const [getMasterAddress, setGetMasterAddress] = useState();
  const [loader, setLoader] = useState(true);
  const [openBid, setOpenBid] = useState(false);
  const [openBid2, setOpenBid2] = useState(false);
  const [openBid3, setOpenBid3] = useState(false);
  const [zerocheck, setzerocheck] = useState(false);
  const [Makeofferinput, setMakeofferinput] = useState();
  const [WRLCBalance, setWRLCBalance] = useState();

  const [sellingIsLoading, setSellingIsLoading] = useState(false);
  const [openBidCheck, setOpenBidCheck] = useState(false);
  const [openBidCheck1, setOpenBidCheck1] = useState(false);
  const [makeOfferPrice, setMakeOfferPrice] = useState();
  const [makeOfferPriceValidation, setMakeOfferPriceValidation] =
    useState(false);
  const [makeOfferLoading, setMakeOfferLoading] = useState(false);
  const [isUserFoundInOfferListing, setIsUserFoundInOfferListing] =
    useState(false);
  const [isCancelListing, setIsCancelListing] = useState(false);
  const [isAcceptOffer, setIsAcceptOffer] = useState(false);
  const [currentpage, setcurrentpage] = useState(0);
  const [offerPerPage, setOfferPerPage] = useState(3);
  const [totalSimpleOffers, setTotalSimpleOffers] = useState(0);
  const [walletAddressBalance, setWalletAddressBalance] = useState(0);

  const [nftHistory, setNftHistory] = useState();

  useEffect(() => {
    http.get(httpUrl + "/api/v1/Nft/GetWRLCAddress").then((res) => {
      checkBalance1(res.data.data, WalletAddress)
        .then((resp) => {
          setWRLCBalance(Web3.utils.fromWei(resp));
        })
        .catch((error) => {});
    });
  }, []);

  useEffect(() => {
    if (NewPrice) {
      if (NewPrice == 0) {
        setSellnftpriceerror1(true);
        // alert("zero")
        setchecksaleminus1(true);
        setSellnftpriceerror(false);
      } else {
        setSellnftpriceerror1(false);
        if (NewPrice < 0) {
          setchecksaleminus(true);
          setchecksaleminus1(true);
          setSellnftpriceerror(true);
        }
        if (NewPrice > 0) {
          setSellnftpriceerror(false);
          setchecksaleminus1(false);
        } else {
          setchecksaleminus1(true);
          setchecksaleminus(false);
        }
        if (NewPrice > 0 && NewPrice < 0.00000001) {
          setmaxpriceofsell(true);
          setchecksaleminus(true);
          setchecksaleminus1(true);
        } else {
          setmaxpriceofsell(false);
        }
        if (NewPrice > 0.00000001) {
          setmaxpriceofsell(false);
          setSellnftpriceerror(false);
          setchecksaleminus1(false);
        }
      }
    } else {
      setmaxpriceofsell(false);
      setchecksaleminus1(true);
      setSellnftpriceerror(false);
      setSellnftpriceerror1(false);
    }
  }, [NewPrice]);

  useEffect(() => {
    if (inputAmount) {
      if (inputAmount < 0) {
        setAmounCheck3(false);
        setAmounCheck4(true);
      } else {
        setAmounCheck4(false);
        if (maxInputAmount) {
          if (inputAmount === maxInputAmount) {
            setAmounCheck3(false);
            setequalcheck(true);
          } else {
            setequalcheck(false);
          }
          if (Number(inputAmount) > Number(maxInputAmount)) {
            setAmounCheck1(true);
          } else {
            setAmounCheck1(false);
          }

          if (
            inputAmount > 0 &&
            inputAmount < parseInt(maxInputAmount) &&
            parseInt(maxInputAmount) > 0
          ) {
            setAmounCheck3(true);
          } else {
            setAmounCheck3(false);
          }
        }
      }
    } else {
      setAmounCheck4(false);
      setequalcheck(false);
      setAmounCheck3(false);
      setAmounCheck(false);
    }
  }, [inputAmount, maxInputAmount]);
  useEffect(() => {
    if (openBid == false) {
      setBidError(false);
      setBidError1(false);
      setAmounCheck1(false);
      setAmounCheck2(false);
      setAmounCheck3(false);
      setAmounCheck4(false);
      setInputAmount(false);
      setIsSwitchOn(false);
      setMaxInputAmount(false);
    }
  }, [openBid]);
  useEffect(() => {
    if (maxInputAmount) {
      if (maxInputAmount >= 0) {
        setAmounCheck6(false);
        if (Number(maxInputAmount) < Number(inputAmount)) {
          setAmounCheck3(false);
          setAmounCheck1(true);
        } else {
          setAmounCheck1(false);
        }
        if (inputAmount === maxInputAmount) {
          setAmounCheck3(false);
          setequalcheck(true);
        } else {
          setequalcheck(false);
        }
      } else {
        setAmounCheck3(false);
        setAmounCheck6(true);
      }
      if (
        inputAmount > 0 &&
        inputAmount < maxInputAmount &&
        maxInputAmount > 0
      ) {
        setAmounCheck3(true);
      } else {
        setAmounCheck3(false);
      }
    } else {
      setAmounCheck1(false);
      setAmounCheck2(false);
      setAmounCheck6(false);
      setAmounCheck3(false);

      setequalcheck(false);
    }
  }, [maxInputAmount, inputAmount]);

  useEffect(async () => {
    getMarketByid();
  }, [id]);

  useEffect(async () => {
    if (myData) {
      viewsCount();
    }
  }, [myData, id]);

  useEffect(async () => {
    if (WalletBalance) {
      const amount = Web3.utils.fromWei(WalletBalance?.toString(), "ether");
      setBalance(amount);
    } else {
      setBalance("0");
    }
  }, [id]);

  useEffect(() => {
    if (myData) {
      profileData();
    }
  }, [myData]);

  useEffect(() => {
    let params = window.location.pathname;
    setParams(params.split("/")[1]);
    setParamsLoading(false);
    if (myData && !isInterval) {
      const eventTime = moment(myData?.bidEndDate).unix();
      const currentTime = moment().unix();

      const diffTime = eventTime - currentTime;
      let duration = moment.duration(diffTime * 1000, "milliseconds");
      const interval = 1000;
      var timerID = setInterval(() => {
        setIsInterval(true);
        if (duration._milliseconds <= 0) {
          setDays("0");
          setHours("0");
          setMinutes("0");
          setSeconds("0");
          setTimer(true);
        } else {
          duration = moment.duration(duration - interval, "milliseconds");

          setDays(duration.days());
          setHours(duration.hours());
          setMinutes(duration.minutes());
          setSeconds(duration.seconds());
          setTimer(true);
        }
      }, interval);
      return () => clearInterval(timerID);
    }
  }, [myData?.bidEndDate]);

  useEffect(() => {
    if (myData) {
      AllBids();
    }
  }, [myData]);

  function ParseFloat(str, val) {
    str = str.toString();
    str = str.slice(0, str.indexOf(".") + val + 1);
    return Number(str);
  }
  const AllBids = () => {};

  // useEffect(() => {
  //   API.GetNftActivityHistory.GetNftActivityByHistoryApi(id).then((response) => {
  //     setNftHistory(response.data.data)
  //   })
  // }, [id])
  useEffect(() => {
    if (filterTrigger == false) {
      setchecksaleminus1(true);
      SetNewPrice(false);
      setSellnftpriceerror(false);
      setmaxpriceofsell(false);
    }
  }, [filterTrigger]);

  const showActivityHistory = () => {
    // zuni
    API.GetNftActivityHistory.GetNftActivityByHistoryApi(
      id,
      currentPage,
      pageSize,
    ).then((response) => {
      setNftHistory(response?.data?.data);
    });
  };

  const viewsCount = async (e) => {
    if (isConnected) {
      await http
        .post(httpUrl + `/api/v1/Nft/AddViewNft?NftId=${myData?.id}`)
        .then((res) => {})
        .catch((e) => {});
    }
  };
  const profileData = async (e) => {
    if (WalletAddress) {
      await http
        .get(httpUrl + `/api/v1/Account/GetAccount?address=${WalletAddress}`)
        .then((res) => {
          setIsStacked(res.data.data.isStacked);
          setStakeLoading(false);
        })
        .catch((e) => {});
    } else {
      setStakeLoading(false);
    }
  };

  const sellingHandler = async (e) => {
    // e.preventDefault();
    setSellingIsLoading(true);
    const address = {
      address: myData.ownerAddress,
    };
    await http
      .post(httpUrl + "/api/v1/auth/connect", address)
      .then(async (res) => {
        toast.success(`Selling in process`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        await http
          .get(httpUrl + "/api/v1/Nft/GetMarketNftAddress")
          .then(async (res) => {
            let marketAddress = res.data.data;
            const payload = {
              approved: marketAddress,
              tokenId: myData?.nftTokenId,
            };
            const payloadMarket = {
              _nftAddress: myData?.contractAddress,
              _nftId: myData?.nftTokenId,
              _price: NewPrice,
            };
            dispatch(
              approveContract(payload, myData?.contractAddress, payloadMarket)
                .then(async (r) => {
                  let body = {
                    nftId: myData?.id,
                    price: NewPrice,
                    approvalTransactionHash: r.response.hash,
                    openOrderTransactionHash: r.res.hash,
                  };
                  http
                    .post(httpUrl + "/api/v1/Nft/SellNftMarket", body)
                    .then(async (res) => {
                      toast.success(
                        `NFT Selling in process, you will be redirected shortly`,
                      );
                      setTimeout(() => {
                        history.push("/explore");
                      }, 3000);
                    });
                })
                .catch((e) => {
                  setSellingIsLoading(false);
                }),
            );
            marketFunction();
            setSellingIsLoading(false);
          });
      })
      .catch((error) => {});
  };

  const marketFunction = (e) => {
    const payloadMarket = {
      nftContractId: myData?.contractAddress,
      tokenId: myData?.nftTokenId,
      price: NewPrice,
    };
    dispatch(
      approveContract(payloadMarket, myData?.contractAddress).then(
        (ress) => {},
      ),
    ).catch((e) => {});
  };
  async function getMarketByid() {
    await http
      .get(httpUrl + `/api/v1/Nft/GetNftMarketByIds?nftId=${id}`)
      .then(async (res) => {
        if (res.data.message === "NFTMarket not found") {
          history.push("/explore");
        }
        setMyData(res.data.data);
        let _nftAddress = res?.data?.data?.contractAddress;
        let _nftId = res?.data?.data?.nftTokenId;
        // offer listing function calling
        await http
          .get(httpUrl + `/api/v1/Nft/GetMarketNftAddress`)
          .then(async (res) => {
            let marketAddress = res.data.data;
            const payload = {
              marketAddress: marketAddress,
              _nftAddress: _nftAddress,
              _nftId: _nftId,
              _page: currentpage,
              _size: offerPerPage,
            };
            dispatch(getOfferListing(payload))
              .then(async (res) => {
                let getOfferList = res;

                const allWisherList = res.map((obj) => obj.wisher);
                if (allWisherList.includes(WalletAddress)) {
                  setIsUserFoundInOfferListing(true);
                } else {
                  setIsUserFoundInOfferListing(false);
                }
                let body = {
                  userAddresses: allWisherList,
                };
                await http
                  .post(httpUrl + `/api/v1/Account/GetUserListByAddress`, body)
                  .then(async (userData) => {
                    let userListFromApi = await userData?.data?.data;

                    const resData = res?.reduce(
                      (acc, [address, offerPrice, wisher]) => {
                        acc[address] = { offerPrice, wisher };
                        return acc;
                      },
                      {},
                    );

                    const mergedArray = userListFromApi?.map((user) => ({
                      ...user,
                      ...resData[user?.address],
                    }));
                    setGetOfferListingData(mergedArray);
                  });
              })
              .catch((err) => {});
          });
        if (GetFavouriteNft?.some((data) => data.id === res.data.data?.id)) {
          // setfavnft(true);
        } else {
          // setfavnft(false);
        }
        setcountoffav(res.data.data?.nftFavouritesCount);
        //  collectionimage(res?.data.data.collectionId)

        setmyDataLoader(false);
        setLoader(false);
      })
      .catch((error) => {});
  }
  const [Acceptloader, setAcceptloader] = useState(true);
  const acceptBidOffer = async (id, price, buyerAddress, contractAddress) => {
    setAcceptloader(false);
    await http
      .get(httpUrl + `/api/v1/Nft/GetMarketNftAddress`)
      .then(async (res) => {
        let marketAddress = res.data.data;
        const payload = {
          contractAddress: marketAddress,
          tokenId: myData?.nftTokenId,
          price: price,
          buyerAddress: buyerAddress,
          nftContractAddress: contractAddress,
        };
        dispatch(acceptBid(payload))
          .then((res) => {
            setAcceptloader(false);
            toast.success(
              `${myData?.isMakeOffer ? "Offer" : "Bid"} has been accepted!`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              },
            );

            http
              .put(
                httpUrl +
                  `/api/v1/Nft/bidAccepted?bidId=${id}&TransactionHash=${res.hash}&IsMakeOffer=${myData?.isMakeOffer}`,
              )
              .then(async (res) => {
                setAcceptloader(true);
                delay(2000).then(async () => {
                  history.push("/explore");
                });
              });
          })
          .catch(() => {
            setAcceptloader(true);
            toast.error(`Transaction rejected`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      });
  };

  useEffect(() => {
    if (Makeofferinput) {
      if (Makeofferinput > 0) {
        setnegetive1(false);
        setnegetive(false);
        if (Makeofferinput > WRLCBalance) {
          setlower(true);
          setmaxbalance(false);
          setnegetive(false);
          setminbalance(false);
        } else if (Makeofferinput < myData?.bidInitialMinimumAmount) {
          setlower(false);
          setmaxbalance(true);
          setnegetive(false);
          setminbalance(false);
        } else if (Makeofferinput <= myData?.bidInitialMinimumAmount) {
          setmakeofferbutton(false);
        } else {
          setlower(false);
          setmaxbalance(false);
          setnegetive(false);
          setminbalance(false);

          setmakeofferbutton(true);
        }
      } else {
        setmakeofferbutton(false);

        setnegetive(false);
        setnegetive1(true);
      }
    } else {
      setmakeofferbutton(false);

      setnegetive1(false);
      setlower(false);
      setmaxbalance(false);
      setnegetive(false);
      setminbalance(false);
    }
  }, [Makeofferinput]);

  const openBidding = async () => {
    setBidInProgress(true);
    let params = window.location.pathname;
    http
      .get(httpUrl + "/api/v1/Nft/GetMarketNftAddress")
      .then(async (res) => {
        let marketAddress = res.data.data;
        const contractPayload = {
          nftContractId: myData?.contractAddress,
          tokenId: myData?.nftTokenId,
          price: isSellBargain ? "0.0000001" : inputAmount,
          maxPrice: isSellBargain ? NewPrice : maxInputAmount,
          contractAddress: marketAddress,
        };
        dispatch(openForAuction(contractPayload))
          .then(async (approvedHashes) => {
            let params = window.location.pathname;
            const payload = {
              nftId: params.split("/")[2],
              isBidOpen: isSellBargain ? false : true,
              minimumAmount: isSellBargain ? "0.0000001" : inputAmount,
              maximumAmount: isSellBargain ? NewPrice : maxInputAmount,
              bidStartDate: startDate,
              bidEndDate: endDate,
              isMakeOffer: isSellBargain,
            };
            http
              .post(httpUrl + "/api/v1/Nft/OpenBid", payload)
              .then(async (res) => {
                let body = {
                  nftId: params.split("/")[2],
                  price: isSellBargain ? NewPrice : maxInputAmount,
                  approvalTransactionHash: approvedHashes.response.hash,
                  openOrderTransactionHash: approvedHashes.response.hash,
                };
                setShow(false);
                setBidtrigger(true);
                await http
                  .post(httpUrl + "/api/v1/Nft/SellNftMarket", body)
                  .then(async (res) => {
                    setBidInProgress(false);
                    toast.success(
                      isSellBargain
                        ? "NFT Selling in process, you will be redirected shortly"
                        : "NFT Bidding has been opened, you will be redirected shortly",
                      {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      },
                    );
                    setTimeout(() => {
                      history.push("/explore");
                    }, 3000);
                  })
                  .catch((err) => {
                    setBidInProgress(false);
                  });
              })
              .catch((err) => {
                setBidInProgress(false);
              });
          })
          .catch((err) => {
            toast.error(`Transaction Rejected`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setSellingIsLoading(false);
            sellingModal();
            setBidInProgress(false);
          });
      })
      .catch((err) => {
        setBidInProgress(false);
      });
  };

  const cancelListing = async () => {
    setCancelLoading(true);
    await http
      .get(httpUrl + "/api/v1/Nft/GetMarketNftAddress")
      .then(async (res) => {
        let marketAddress = res.data.data;

        const contractPayload = {
          marketAddress: marketAddress,
          _nftAddress: myData?.contractAddress,
          _nftId: myData?.nftTokenId,
        };
        dispatch(cancelNft(contractPayload))
          .then(async (approvedHash) => {
            const hashPayload = {
              nftId: myData?.id,
              cancelTransactionHash: approvedHash.hash,
            };
            await http
              .post(httpUrl + "/api/v1/Nft/NftCancelStatus", hashPayload)
              .then(async (res) => {
                toast.success(`NFT has been unlisted from marketplace`, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setCancelLoading(false);
                setTimeout(() => {
                  history.push("/myprofile");
                }, 3000);
              });
          })
          .catch(() => {
            toast.error(`Transaction Rejected`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setCancelLoading(false);
          });
      });
  };

  const startDateFunc = (date) => {
    let dateNow = new Date();
    dateNow = moment(dateNow).format("YYYY-MM-DD");
    let dateEntered = moment(date).format("YYYY-MM-DD");
    let edDate = moment(endDate).format("YYYY-MM-DD");

    if (dateEntered < dateNow || date === null) {
      setBidError(true);
      setBidError2(false);
      setStartDate(date);
    } else if (dateEntered > edDate) {
      setBidError(false);
      setBidError2(true);
      setStartDate(date);
    } else {
      setBidError2(false);
      setBidError(false);
      setStartDate(date);
    }
  };

  const endDateFunc = (date) => {
    let dateNow = new Date();
    dateNow = moment(dateNow).format("YYYY-MM-DD");
    let dateEntered = moment(date).format("YYYY-MM-DD");
    let stDate = moment(startDate).format("YYYY-MM-DD");

    if (dateEntered < dateNow || dateEntered < stDate || date === null) {
      setBidError1(true);
      setEndDate(date);
    } else {
      setBidError1(false);
      setBidError2(false);
      setEndDate(date);
    }
  };

  const switchStatus = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const postBidding = async () => {
    setIsOfferInProgress(true);
    let params = window.location.pathname;
    const amount = Web3.utils.fromWei(WalletBalance.toString(), "ether");
    setBalance(amount);
    http.get(httpUrl + "/api/v1/Nft/GetWRLCAddress").then(async (response) => {
      http
        .get(httpUrl + `/api/v1/Nft/GetMarketNftAddress`)
        .then(async (res) => {
          let marketAddress = res.data.data;

          const contractPayload = {
            marketPlaceContract: marketAddress,
            ownerAddress: myData?.ownerAddress,
            contractAddress: response?.data?.data,
            id: myData?.nftTokenId,
            price: myData?.isMakeOffer ? makeOfferPrice : Makeofferinput,
          };
          setBidtrigger(false);
          dispatch(approveNft(contractPayload))
            .then((res) => {
              toast.success(
                `${myData?.isMakeOffer ? "Offer" : "Bidding"} is in process!`,
                {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                },
              );
              delay(15000).then(async () => {
                biddingPosting(res.hash);
              });
            })
            .catch(() => {
              toast.error(`Transaction rejected`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setMakeOfferPrice("");
              setMakeOfferLoading(false);
              setGiveMakeOfferValueModalShow(false);
              setIsOfferInProgress(false);
            });
        });
    });
  };

  const biddingPosting = async (hash) => {
    let params = window.location.pathname;
    let expp = moment(expiryDate).unix();
    let current = moment(expiryDate).unix();
    let diff = expp - current;
    let duration = moment.duration(diff * 1000, "milliseconds");

    const payload = {
      nftId: params.split("/")[2],
      price: myData?.isMakeOffer ? makeOfferPrice : Makeofferinput,
      bidApprovalHash: hash,
      expiryDate: expiryDateplacebid,
      isMakeOffer: myData?.isMakeOffer,
    };
    http
      .post(httpUrl + "/api/v1/Nft/AddNftBid", payload)
      .then(async (res) => {
        toast.success(
          `${myData?.isMakeOffer ? "Offer" : "Bid"} has been added!`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );

        setMakeOfferPriceValidation(false);
        setIsSwitchOn(false);
        setMakeofferinput();
        setIsOfferInProgress(false);
        getBiddings(payload);
        handleBtnClick1();

        setMakeOfferPrice("");
        setMakeOfferLoading(false);
        setGiveMakeOfferValueModalShow(false);
      })
      .catch((error) => {
        biddingPosting();
        setIsOfferInProgress(false);
      });
  };

  const getBiddings = async (payload) => {
    await http
      .get(httpUrl + `/api/v1/Nft/GetNftBids?NftId=${payload.nftId}`)
      .then(async (res) => {
        if (!res.data.data || res.data.data === null) {
          setEmptyBids(true);
        }
        setEmptyBids(false);
        setBiddings(res.data.data);
        setIsLoading(false);
        setIsBiddingLoading(false);
        setShow(false);
        setBidtrigger(true);
      })
      .catch(() => {
        getBiddings(payload);
      });
  };

  const buyNft = async () => {
    if (!isConnected || isConnected == undefined) {
      return history.push("/connectwallet");
    } else {
      setBidInProgress(true);
      await http
        .get(httpUrl + `/api/v1/Nft/GetMarketNftAddress`)
        .then(async (res) => {
          let marketAddress = res.data.data;
          const payload = {
            market_address: marketAddress,
            _nftAddress: myData?.contractAddress,
            _nftId: myData?.nftTokenId,
            price: myData?.sellPrice,
          };

          dispatch(buyNftMarket(payload))
            .then((res) => {
              toast.success(`NFT purchasing in process. Please wait.`);
              toast.success(`NFT bought!`);
              const payload1 = {
                transactionHash: res.hash,
                nftId: myData?.id,
              };
              buyNftMarketFunc(payload1);
            })
            .catch((err) => {
              toast.error(`Transaction rejected`);
              setBidInProgress(false);
            });
        });
    }
  };

  const buyNftMarketFunc = async (payload) => {
    await http
      .post(httpUrl + "/api/v1/Nft/BuyNftMarket", payload)
      .then(async (response) => {
        setBidInProgress(false);

        setTimeout(() => {
          // history.push("/myprofile");
          history.push({
            pathname: `/myprofile`,
            state: "bought",
          });
          localStorage.clear();
        }, 1000);
      })
      .catch((err) => {
        buyNftMarketFunc(payload);
        // setBidInProgress(false);
      });
  };

  const removeFromLike = (id, owner) => {
    setbuttonclicked(true);
    if (!isConnected) {
      toast.error(`Please connect to wallet first`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setbuttonclicked(false);
      return;
    }
    http
      .put(httpUrl + "/api/v1/Nft/RemoveFavouriteNft", {
        nftId: id,
        nftAddress: owner,
      })
      .then((resp) => {
        toast.success(`Removed from favourite`);
        dispatch(GetFavouriteNftAction())
          .then((resp) => {
            // likeAndDisLikeCallback()
            setbuttonclicked(false);
            setfavnft(false);
            setcountoffav(countoffav - 1);
          })
          .catch((error) => {
            setbuttonclicked(false);
          });
      })
      .catch((error) => {
        setbuttonclicked(false);
      });
  };

  const addToLike = (id, owner) => {
    setbuttonclicked(true);
    if (!isConnected) {
      toast.warn(`Please connect to wallet first`);

      setbuttonclicked(false);
      return;
    }
    http
      .post(httpUrl + "/api/v1/Nft/AddFavouriteNft", {
        nftId: id,
        nftAddress: owner,
      })
      .then((resp) => {
        toast.success(`Added to favourite`);

        dispatch(GetFavouriteNftAction())
          .then((resp) => {
            // likeAndDisLikeCallback()
            setfavnft(true);
            setcountoffav(countoffav + 1);
            setbuttonclicked(false);
          })
          .catch((error) => {
            setbuttonclicked(false);
          });
      })
      .catch((error) => {
        setbuttonclicked(false);
      });
  };
  const handleSellValidatio = (data) => {
    const regex = /^[0-9]{0,9}(\.[0-9]{1,5})?$|^(100)(\.[0]{1,4})?$/;

    if (regex.test(data)) {
      setSelValid(false);
      SetNewPrice(data);
    }

    if (data == 0) {
      setSelValid(true);
    }
  };

  const [toggleIcon, setToggleIcon] = useState(false);
  const [toggleIconDetail, setToggleIconDetail] = useState(false);

  // make offer function
  const makeSimpleListingOffer = async (nftDetails, userOfferPrice) => {
    if (!isConnected || isConnected == undefined) {
      return history.push("/connectwallet");
    } else {
      setMakeOfferPriceValidation(false);
      setMakeOfferLoading(true);
      await http
        .get(httpUrl + `/api/v1/Nft/GetMarketNftAddress`)
        .then(async (res) => {
          let marketAddress = res.data.data;
          const payload = {
            marketAddress: marketAddress,
            _nftAddress: nftDetails?.contractAddress,
            _nftId: nftDetails?.nftTokenId,
            price: makeOfferPrice,
          };

          dispatch(makeOffer(payload))
            .then((res) => {
              toast.success(`NFT Offer Added!`);
              setMakeOfferLoading(false);
              showListingOffers();
              setOpenBid2(false);
              setMakeOfferPrice("");
              setMakeOfferPriceValidation(true);
            })
            .catch((err) => {
              toast.error(`Transaction rejected`);
              setMakeOfferLoading(false);
              setMakeOfferPriceValidation(true);
            });
        });
    }
  };

  // accept offer listing
  const handleAcceptOffer = async (userAddress, price, index) => {
    // calling abi of acceptSimpleListingOffer
    setIsAcceptOffer(index);
    await http
      .get(httpUrl + `/api/v1/Nft/GetMarketNftAddress`)
      .then(async (res) => {
        let marketAddress = res.data.data;
        const payload = {
          marketAddress: marketAddress,
          _nftAddress: myData?.contractAddress,
          _nftId: myData?.nftTokenId,
          _buyer: userAddress,
        };
        dispatch(acceptSimpleListingOffer(payload))
          .then((res) => {
            showListingOffers();
            let body = {
              userAddress: userAddress,
              price: parseFloat(price) / 1e18,
              transactionHash: res.hash,
              nftId: myData?.id,
            };
            setIsAcceptOffer(false);
            http
              .post(httpUrl + "/api/v1/Nft/BuyMakeOfferNft", body)
              .then(async (res) => {
                toast.success(`Offer has been Accepted!`);
                setIsAcceptOffer(false);
                setTimeout(() => {
                  history.push("/explore");
                }, 1000);
              })
              .catch((err) => {
                toast.error("Api Calling rejected");
                setIsAcceptOffer(false);
              });
          })
          .catch((err) => {
            toast.error(`Transaction rejected`);
            setIsAcceptOffer(false);
          });
      });
  };

  // cancel offer
  const handleCancelOffer = async (nftDetails, userOfferPrice) => {
    await http
      .get(httpUrl + `/api/v1/Nft/GetMarketNftAddress`)
      .then(async (res) => {
        let marketAddress = res.data.data;
        const payload = {
          marketAddress: marketAddress,
          _nftAddress: myData?.contractAddress,
          _nftId: myData?.nftTokenId,
        };
        dispatch(cancelListingOffer(payload))
          .then((res) => {
            setIsUserFoundInOfferListing(false);
            setMakeOfferLoading(false);
            toast.success(`Offer Cancelled`);
            setIsCancelListing(false);

            const filteredData = getOfferListingData.filter(
              (item) =>
                item?.address?.toLocaleLowerCase() !==
                WalletAddress?.toLocaleLowerCase(),
            );
            setGetOfferListingData(filteredData);
          })
          .catch((err) => {
            toast.error(`Transaction rejected`);
            setMakeOfferLoading(false);
            setIsCancelListing(false);
          });
      });
  };

  // offer listing function
  const showListingOffers = async (nextPage) => {
    let temp = offerPerPage;
    await http
      .get(httpUrl + `/api/v1/Nft/GetNftMarketByIds?nftId=${id}`)
      .then(async (res) => {
        if (res.data.message === "NFTMarket not found") {
          history.push("/explore");
        }
        setMyData(res.data.data);
        let _nftAddress = res?.data?.data?.contractAddress;
        let _nftId = res?.data?.data?.nftTokenId;
        // offer listing function calling
        await http
          .get(httpUrl + `/api/v1/Nft/GetMarketNftAddress`)
          .then(async (res) => {
            let marketAddress = res.data.data;
            const payload = {
              marketAddress: marketAddress,
              _nftAddress: _nftAddress,
              _nftId: _nftId,
              _page: currentpage,
              _size: temp + 3,
            };
            dispatch(getOfferListing(payload))
              .then(async (res) => {
                let getOfferList = res;
                setOfferPerPage(temp + 3);
                const allWisherList = res.map((obj) => obj.wisher);
                if (allWisherList.includes(WalletAddress)) {
                  setIsUserFoundInOfferListing(true);
                } else {
                  setIsUserFoundInOfferListing(false);
                }
                let body = {
                  userAddresses: allWisherList,
                };
                await http
                  .post(httpUrl + `/api/v1/Account/GetUserListByAddress`, body)
                  .then(async (userData) => {
                    let userListFromApi = await userData?.data?.data;
                    const resData = res?.reduce(
                      (acc, [address, offerPrice, wisher]) => {
                        acc[address] = { offerPrice, wisher };
                        return acc;
                      },
                      {},
                    );

                    const mergedArray = userListFromApi?.map((user) => ({
                      ...user,
                      ...resData[user?.address],
                    }));
                    setGetOfferListingData(mergedArray);
                  });
              })
              .catch((err) => {});
          });

        setcountoffav(res.data.data?.nftFavouritesCount);
        //  collectionimage(res?.data.data.collectionId)

        setmyDataLoader(false);
        setLoader(false);
      })
      .catch((error) => {});
  };

  // count all ofers
  const countAllOffers = async () => {
    await http
      .get(httpUrl + `${GET_NFT_MARKET_BY_IDS}?nftId=${id}`)
      .then(async (res) => {
        if (res.data.message === "NFTMarket not found") {
          history.push("/explore");
        }
        setMyData(res.data.data);
        let _nftAddress = res?.data?.data?.contractAddress;
        let _nftId = res?.data?.data?.nftTokenId;
        await http.get(httpUrl + GET_MARKET_NFT_ADDRESS).then(async (res) => {
          let marketAddress = res.data.data;
          const payload = {
            marketAddress: marketAddress,
            _nftAddress: _nftAddress,
            _nftId: _nftId,
          };
          dispatch(getTotalOffersCount(payload))
            .then(async (res) => {
              setTotalSimpleOffers(parseInt(res, 16));
            })
            .catch((err) => {});
        });
        setcountoffav(res.data.data?.nftFavouritesCount);
        setmyDataLoader(false);
        setLoader(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setOpenMenu1(true);
    showListingOffers();
    countAllOffers();
  }, []);

  useEffect(async () => {
    if (!isConnected || isConnected === undefined) {
      return history.push("/connectwallet");
    } else {
      if (WalletBalance) {
        const amount = Web3.utils.fromWei(WalletBalance?.toString(), "ether");
        setWalletAddressBalance(amount);
      }
    }
  }, []);

  useEffect(async () => {
    getMarketByid();
    if (isConnected) {
      await http.get(httpUrl + "/api/v1/Nft/ListNftIds").then((res) => {
        let data = res?.data?.data.some((x) => x == id);
        if (res?.data?.data?.some((x) => x == id)) {
          setfavnft(true);
        }
      });
    }
  }, [id]);

  const showMoreNFTHistory = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    let temp = pageSize + 5;
    try {
      const response =
        await API.GetNftActivityHistory.GetNftActivityByHistoryApi(
          id,
          currentPage,
          temp,
        );
      setNftHistory(response?.data?.data);
      let length = response?.data?.data?.length;
    } catch (error) {}
    setPageSize(temp);
    setLoading(false);
  };

  return (
    <div className="gradient-bg-light">
      <GlobalStyles />
      {loader ? (
        <>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div className="col-sm-12 d-flex justify-content-center margin-top-150">
            <BounceLoader color="white" size="60" />
          </div>
        </>
      ) : (
        <>
          <section className="container user-nft-head">
            <div className="row ">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div
                  className={
                    myData?.image.includes(".mp4")
                      ? "my-profile-Img-pnl video-pnl full-div"
                      : "my-profile-Img-pnl full-div"
                  }
                >
                  {myData?.isVerifiedNft && (
                    <span className="check-span-span">
                      <i className="fa fa-check"></i>
                    </span>
                  )}
                  {myData?.image.includes(".mp4") ? (
                    <video
                      style={{ width: "100%", height: "100%" }}
                      src={myData?.image}
                      controls
                      currentTime={11.3}
                    />
                  ) : (
                    <img
                      src={myData?.image}
                      className="img-fluid img-rounded mb-sm-30"
                      alt="NFT.png"
                    />
                  )}

                  <div className="user-fav-heart-span">
                    {favnft ? (
                      <img
                        src={heart}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          buttonclicked ? (
                            <></>
                          ) : (
                            removeFromLike(myData?.id, myData?.ownerAddress)
                          );
                        }}
                      />
                    ) : (
                      <img
                        src={heartBlack}
                        style={{ width: "15px", cursor: "pointer" }}
                        onClick={() => {
                          buttonclicked ? (
                            <></>
                          ) : (
                            addToLike(myData?.id, myData?.ownerAddress)
                          );
                        }}
                      />
                    )}
                    {countoffav}
                  </div>
                </div>

                <div id="zero3" className="onStep fadeIn">
                  <div id="accordion">
                    <div class="card">
                      <div
                        onClick={() => {
                          setToggleIconDetail(!toggleIconDetail);
                        }}
                        class="card-header"
                        id="headingOne"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        style={{
                          borderBottom: "2px solid white",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <h4>
                          <i
                            class="fa fa-tag"
                            style={{
                              color: "white",
                              transform: "rotate(135deg)",
                              marginRight: 10,
                            }}
                          />
                          Traits
                        </h4>
                        <h4>
                          {toggleIconDetail ? (
                            <i class="fa fa-sort-desc" aria-hidden="true" />
                          ) : (
                            <i class="fa fa-sort-asc" aria-hidden="true" />
                          )}
                        </h4>
                      </div>

                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          <div
                            className="col-lg-12"
                            id="traits-body"
                            style={{
                              height: myData?.nftProperties?.length
                                ? "250px"
                                : "auto",
                            }}
                          >
                            <div style={{ color: "white" }}>
                              <Row>
                                {myData?.nftProperties.length > 0 ? (
                                  <>
                                    {myData?.nftProperties.map(
                                      (data, index) => (
                                        <Col
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={12}
                                          className={
                                            "d-flex justify-content-center flex-column align-items-center mt-3 word-break-breakall"
                                          }
                                          key={index}
                                        >
                                          <div
                                            className="w-100"
                                            style={{
                                              backgroundColor:
                                                "rgba(21, 178, 229, 0.06)",
                                              borderRadius: 6,
                                              border: "1px solid #E250E5",
                                              padding: "5px 5px",
                                              textAlign: "center",
                                              wordBreak: "break-word",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p style={{ textAlign: "center" }}>
                                              {data.name}
                                            </p>
                                            <h4 className="">
                                              <strong>{data.type} </strong>
                                            </h4>
                                            <p style={{ textAlign: "center" }}>
                                              {data.rarity + "%"}
                                            </p>
                                          </div>
                                        </Col>
                                      ),
                                    )}
                                  </>
                                ) : (
                                  <> No Properties to show </>
                                )}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="accordion2">
                    <div class="card">
                      <div
                        onClick={() => {
                          setToggleIcon(!toggleIcon);
                        }}
                        class="card-header"
                        id="headingTwo"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                        style={{
                          borderBottom: "2px solid white",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <h4>
                          <i
                            class="fa fa-info-circle"
                            style={{
                              color: "white",
                              marginRight: 10,
                            }}
                          />
                          Details
                        </h4>
                        <h4>
                          {toggleIcon ? (
                            <i class="fa fa-sort-asc" aria-hidden="true" />
                          ) : (
                            <i class="fa fa-sort-desc" aria-hidden="true" />
                          )}
                        </h4>
                      </div>

                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion2"
                      >
                        <div class="card-body" style={{ paddingLeft: 0 }}>
                          <div className="col-lg-12">
                            <div style={{ color: "white" }}>
                              <p
                                className="clrd d-flex flex-column"
                                style={{ cursor: "pointer" }}
                              >
                                <b style={{ cursor: "initial" }}>
                                  Contract Address :{" "}
                                </b>
                                <a
                                  target="_blank"
                                  // href={
                                  //   "https://redlightscan.finance/address/" +
                                  //   myData?.contractAddress
                                  // }
                                  style={{ color: "#E250E5" }}
                                >
                                  {myData?.contractAddress}
                                </a>
                              </p>
                              <p className="clrd">
                                <b>Token ID : </b>
                                {myData?.iPfsURL ? (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => window.open(myData?.iPfsURL)}
                                  >
                                    {myData?.nftTokenId}
                                  </span>
                                ) : (
                                  <span>{myData?.nftTokenId}</span>
                                )}
                              </p>
                              <p className="clrd">
                                <b>Metadata :</b>{" "}
                                {myData?.isMinted ? "Not editable" : "Editable"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="user-nft-info-pnl">
                  <div className="full-div">
                    <h3 className="info-name">
                      <span className="hoverdetail">{myData?.name}</span>
                    </h3>
                  </div>
                </div>
                <ul className="creator-list">
                  <li
                    onClick={() => {
                      history.push(
                        myData?.ownerAddress?.toLocaleLowerCase() ===
                          WalletAddress?.toLocaleLowerCase()
                          ? `/myprofile`
                          : `/profile/${myData?.ownerAddress}`,
                      );
                      localStorage.clear();
                    }}
                  >
                    <div className="flex">
                      <div className="img-pnl">
                        <img
                          src={
                            myData?.ownerImage
                              ? `${myData?.ownerImage}`
                              : defaultImg
                          }
                          alt="NFT.png"
                        />
                      </div>
                      <div className="txt-pnl">
                        <span>owned by</span>{" "}
                        <h6>
                          {myData?.ownerName ? myData?.ownerName : "Unnamed"}{" "}
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li
                    onClick={() =>
                      history.push(
                        myData?.creatorAddress?.toLocaleLowerCase() ===
                          WalletAddress?.toLocaleLowerCase()
                          ? `/myprofile`
                          : `/profile/${myData?.creatorAddress}`,
                      )
                    }
                  >
                    <div className="flex">
                      <div className="img-pnl">
                        <img
                          src={
                            myData?.creatorImage
                              ? `${myData?.creatorImage}`
                              : defaultImg
                          }
                          alt="NFT.png"
                        />
                      </div>
                      <div className="txt-pnl">
                        <span>created by</span>{" "}
                        <h6>
                          {myData?.creatorName
                            ? myData?.creatorName
                            : "Unnamed"}
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>

                <div className="flex-div">
                  <ul className="share-info-list">
                    <li>
                      <p
                        className="cursor-pointor"
                        onClick={() => {
                          favnft
                            ? removeFromLike(myData?.id, myData?.ownerAddress)
                            : addToLike(myData?.id, myData?.ownerAddress);
                        }}
                      >
                        <i
                          className={favnft ? "fa fa-heart" : "fa fa-heart-o"}
                          color={"red"}
                        >
                          {countoffav}{" "}
                        </i>
                      </p>
                    </li>
                    <li>
                      <p>
                        <i className="fa fa-eye"> {myData?.viewCount + " "}</i>
                      </p>
                    </li>
                    <li
                      title="Refresh"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      <p
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        <i class="fa fa-refresh"></i>
                      </p>
                    </li>
                    <CopyToClipboard
                      text={window.location.href}
                      onCopy={() => {
                        disableDispatch({ type: "clicked" });
                        toast.success("NFT URL copied successfully", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: true,
                          progress: undefined,
                        });
                        setTimeout(() => {
                          disableDispatch({ type: "notClicked" });
                        }, 5000);
                      }}
                    >
                      <li title="Copy">
                        <p>
                          <i class="fas fa-copy"></i>
                        </p>
                      </li>
                    </CopyToClipboard>
                    {myData?.externalLink && (
                      <li
                        onClick={() => {
                          window.open(myData?.externalLink);
                        }}
                        title="Open NFT"
                      >
                        <p>
                          <i class="fa fa-external-link"></i>
                        </p>
                      </li>
                    )}
                  </ul>
                </div>

                <div className="description-details-text w-100">
                  <div className="full-div"></div>

                  <h6>
                    {myData?.isBidOpen ? (
                      <>Current Bid</>
                    ) : myData?.sellPrice || myData?.buyPrice ? (
                      "Price"
                    ) : (
                      ""
                    )}
                  </h6>

                  {myData?.isBidOpen && (
                    <div style={{ fontSize: "20px" }}>
                      <span>
                        Min price --{" "}
                        {myData?.bidInitialMinimumAmount
                          ? myData?.bidInitialMinimumAmount + " ETH"
                          : "Min price not set."}
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        Max price --{" "}
                        {myData?.bidInitialMaximumAmount
                          ? myData?.bidInitialMaximumAmount + " ETH"
                          : "Max price not set."}
                      </span>
                    </div>
                  )}
                  <p>
                    {myData?.isBidOpen ? (
                      <div style={{ paddingTop: "10px" }}></div>
                    ) : myData?.sellPrice || myData?.buyPrice ? (
                      <>
                        <img
                          src={rlc}
                          style={{
                            display: "inline-block",
                            maxWidth: "20px",
                            marginRight: "4px",
                            marginBottom: "4px",
                            position: "relative",
                            top: "-6px",
                            // display: inline-block;
                            // max-width: 20px;
                            // margin-right: 4px;
                          }}
                        />{" "}
                        <b>
                          {myData?.sellPrice
                            ? myData?.sellPrice
                            : myData?.buyPrice}
                        </b>{" "}
                        {myData?.isMakeOffer ? "  ETH" : "  ETH"}
                      </>
                    ) : (
                      <div style={{ paddingTop: "10px" }}></div>
                    )}
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-7 col-md-12 col-sm-12">
                    <div className="row">
                      {isConnected &&
                        myData?.ownerAddress?.toLocaleLowerCase() !=
                          WalletAddress?.toLocaleLowerCase() &&
                        myData?.isBidOpen &&
                        !myData?.isMakeOffer && (
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-1">
                            <a
                              onClick={handleShow}
                              id="btnBuy"
                              type="submit"
                              className="reg-btn w-100"
                            >
                              <i className="fa fa-shopping-cart"></i> Place Bid
                            </a>
                          </div>
                        )}

                      {isConnected &&
                        myData &&
                        myData?.ownerAddress?.toLocaleLowerCase() !=
                          WalletAddress?.toLocaleLowerCase() &&
                        !myData?.isBidOpen &&
                        myData?.staus !== "Hold" &&
                        !myData?.isMakeOffer && (
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-1">
                            {!bidInProgress ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <a
                                  onClick={buyNft}
                                  id="btnBuy"
                                  type="submit"
                                  className="reg-btn w-100"
                                >
                                  Buy
                                </a>
                                <a
                                  id="btnBuy"
                                  type="submit"
                                  className="reg-btn w-100 mx-2"
                                  onClick={() => {
                                    if (isUserFoundInOfferListing) {
                                      toast.warning(
                                        "An offer is already placed",
                                      );
                                    } else {
                                      setOpenBid2(true);
                                    }
                                  }}
                                >
                                  Make Offer
                                </a>
                              </div>
                            ) : (
                              <a id="btnBuy" className="reg-btn w-100" disabled>
                                <PulseLoader
                                  color={WHITE_COLOR}
                                  size={LOADER_MEDIUM_SIZE}
                                />
                              </a>
                            )}
                          </div>
                        )}

                      {isConnected && myData && (
                        <>
                          {myData?.ownerAddress?.toLocaleLowerCase() !=
                            WalletAddress?.toLocaleLowerCase() &&
                            myData?.isMakeOffer &&
                            myData?.staus !== "Hold" && (
                              <div className="col-lg-6 col-md-6 col-sm-12 mt-1">
                                {!bidInProgress ? (
                                  <a
                                    onClick={() => {
                                      setGiveMakeOfferValueModalShow(true);
                                    }}
                                    id="btnBuy"
                                    type="submit"
                                    className="reg-btn w-100"
                                  >
                                    Make Offer
                                  </a>
                                ) : (
                                  <a
                                    id="btnBuy"
                                    className="reg-btn w-100"
                                    disabled
                                  >
                                    <PulseLoader color="white" size="15" />
                                  </a>
                                )}
                              </div>
                            )}
                        </>
                      )}

                      {/* model for make listing */}
                      <div className="full-div">
                        <Modal centered show={openBid2} onHide={handleClose2}>
                          <Modal.Header className=" d-flex justify-content-center align-items-center">
                            <Modal.Title className="text-dark">
                              <span>Place an Offer</span>
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <form
                                  name="contactForm"
                                  id="contact_form"
                                  className="form-border"
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <div className="field-set">
                                    <label className="make-offer-model-label">
                                      Price in ETH:
                                    </label>
                                    <FormControl
                                      className="black"
                                      placeholder="Amount"
                                      type="number"
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        setMakeOfferPrice(value);
                                        if (
                                          value > 0 &&
                                          value <= myData?.sellPrice
                                        ) {
                                          setMakeOfferPriceValidation(true);
                                        } else {
                                          setMakeOfferPriceValidation(false);
                                        }
                                      }}
                                      value={makeOfferPrice}
                                      aria-label="Amount (to the nearest dollar)"
                                    />

                                    <p
                                      className="mb-4 ml-2 mt-0"
                                      style={{
                                        color:
                                          makeOfferPrice > myData?.sellPrice
                                            ? RED_COLOR
                                            : BLACK_COLOR,
                                        fontSize: 14,
                                      }}
                                    >
                                      Max Offer value {myData?.sellPrice} ETH
                                    </p>
                                  </div>

                                  <div className=" d-flex justify-content-center align-items-center">
                                    {!makeOfferPriceValidation ? (
                                      <button className="reg-new-btn" disabled>
                                        {makeOfferLoading ? (
                                          <PulseLoader
                                            color={WHITE_COLOR}
                                            size={LOADER_SMALL_SIZE}
                                          />
                                        ) : (
                                          "Make Offer"
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        className="reg-btn"
                                        onClick={() => {
                                          if (
                                            Number(makeOfferPrice) <=
                                            Number(balance)
                                          ) {
                                            setMakeOfferLoading(true);
                                            if (
                                              Number(makeOfferPrice) ===
                                              Number(myData?.sellPrice)
                                            ) {
                                              handleClose2();
                                              setMakeOfferLoading(false);
                                              setOpenBid3(true);
                                            } else
                                              makeSimpleListingOffer(
                                                myData,
                                                makeOfferPrice,
                                              );
                                          } else if (
                                            Number(makeOfferPrice) >
                                            Number(balance)
                                          ) {
                                            toast.error(
                                              `You don't have enough ETH`,
                                            );
                                            return;
                                          } else if (
                                            Number(makeOfferPrice) ==
                                            Number(myData?.sellPrice)
                                          ) {
                                            handleClose2();
                                            setOpenBid3(true);
                                          } else if (
                                            makeOfferPrice?.length == 0
                                          ) {
                                            setMakeOfferLoading(false);
                                            toast.error(
                                              "You Can't add 0 value",
                                            );
                                          } else {
                                          }
                                        }}
                                      >
                                        {makeOfferLoading ? (
                                          <PulseLoader
                                            color={WHITE_COLOR}
                                            size={LOADER_SMALL_SIZE}
                                          />
                                        ) : (
                                          "Make Offer"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>

                      {/* model for make listing */}

                      <div className="full-div">
                        <Modal centered show={openBid3} onHide={handleClose3}>
                          <Modal.Body>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <h5 className="text-dark">
                                  The price you are entering is the actual price
                                  of an NFT
                                </h5>
                                <h5 className="text-dark ">
                                  Do you wish to purchase it?
                                </h5>
                              </div>
                            </div>
                          </Modal.Body>

                          <Modal.Footer className="d-flex justify-content-center">
                            <button
                              className="reg-btn"
                              onClick={() => {
                                buyNft();
                                setOpenBid3(false);
                              }}
                            >
                              Buy
                            </button>
                            <button
                              className="reg-btn"
                              onClick={() => {
                                setOpenBid3(false);
                                setMakeOfferPrice("");
                              }}
                            >
                              Cancel
                            </button>
                          </Modal.Footer>
                        </Modal>
                      </div>

                      {(myData?.staus === "ReadyForSell" ||
                        myData?.isBidOpen === true) &&
                        myData?.ownerAddress?.toLocaleLowerCase() ===
                          WalletAddress?.toLocaleLowerCase() && (
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-1">
                            {cancelLoading ? (
                              <>
                                <a
                                  href="javascript:void(0);"
                                  className="reg-btn w-100"
                                >
                                  <PulseLoader color="white" size="15" />
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  onClick={() => cancelListing()}
                                  href="javascript:void(0);"
                                  className="reg-btn w-100"
                                >
                                  <i class="fa fa-suitcase"></i> De-List
                                </a>
                              </>
                            )}
                          </div>
                        )}

                      {!myData?.freezeData &&
                        myData?.ownerAddress?.toLocaleLowerCase() ==
                          WalletAddress?.toLocaleLowerCase() && (
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-1">
                            <a
                              onClick={() => {
                                localStorage.clear();
                                history.push(
                                  `/createnft/${myData?.ownerAddress}/${myData?.id}/${myData?.accountId}`,
                                );
                              }}
                              href="javascript:void(0);"
                              className="reg-btn w-100 text-white"
                            >
                              <i class="fa fa-suitcase"></i> Update NFT
                            </a>
                          </div>
                        )}

                      {myData?.staus != "ReadyForSell" &&
                        myData?.isMinted === true &&
                        myData?.ownerAddress?.toLocaleLowerCase() ===
                          WalletAddress?.toLocaleLowerCase() && (
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-1">
                            <a
                              onClick={() => setFilterTrigger(true)}
                              href="javascript:void(0);"
                              className="reg-btn w-100 text-white"
                            >
                              <i class="fa fa-suitcase"></i> List for Sale
                            </a>
                          </div>
                        )}

                      {/* {((myData?.staus != "ReadyForSell" &&
                        myData?.isMinted === true) && (myData?.ownerAddress?.toLocaleLowerCase() === WalletAddress?.toLocaleLowerCase())) && (
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-1">
                            <a onClick={() => {

                              setIsSellBargain(true)
                              setFilterTrigger(true)

                            }} href="javascript:void(0);" className="reg-btn w-100">
                              <i class="fa fa-suitcase"></i> Bargain Sell NFT</a>
                          </div>
                        )} */}

                      {/* {myData?.staus != "ReadyForSell" && myData?.isMinted === true && myData?.ownerAddress === WalletAddress && !myData?.isBidOpen && (
                        <div className="col-lg-6 col-md-6 col-sm-12 mt-1">
                          <a onClick={() => setOpenBid(true)} href="javascript:void(0);" className="reg-btn w-100"><i class="fa fa-handshake-o"></i> Open for bid</a>
                        </div>

                      )} */}
                      {myData?.ownerAddress?.toLocaleLowerCase() ==
                        WalletAddress?.toLocaleLowerCase() &&
                        myData?.staus == "Hold" &&
                        !myData?.isImport && (
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-1">
                            <a
                              onClick={() => {
                                setDeleteNFTModalShow(true);
                              }}
                              href="javascript:void(0);"
                              className="reg-btn w-100"
                            >
                              <i class="fa fa-trash"></i> Delete NFT
                            </a>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-lg-5 col-md-12 col-sm-12">
                    <div className="nft-timer">
                      {
                        <>
                          {myData?.isBidOpen && (
                            <>
                              {/* <h6>Sale ends {dateFormat(myData?.bidEndDate + "z", "mmm dd, yyyy")} at {dateFormat(myData?.bidEndDate + "z", "HH:MM TT")} </h6> */}

                              <ul className="timer">
                                <>
                                  <li>
                                    <p>
                                      {days ? (
                                        days
                                      ) : days === 0 ? (
                                        0
                                      ) : (
                                        <BounceLoader
                                          color="white"
                                          size="20"
                                          width="10"
                                        />
                                      )}
                                    </p>
                                  </li>
                                  <li>:</li>
                                  <li>
                                    <p>
                                      {hours ? (
                                        hours
                                      ) : hours === 0 ? (
                                        0
                                      ) : (
                                        <BounceLoader
                                          color="white"
                                          size="20"
                                          width="10"
                                        />
                                      )}
                                    </p>
                                  </li>
                                  <li>:</li>
                                  <li>
                                    <p>
                                      {minutes ? (
                                        minutes
                                      ) : minutes === 0 ? (
                                        0
                                      ) : (
                                        <BounceLoader
                                          color="white"
                                          size="20"
                                          width="10"
                                        />
                                      )}
                                    </p>
                                  </li>
                                  <li>:</li>
                                  <li>
                                    <p>
                                      {seconds ? (
                                        seconds
                                      ) : seconds === 0 ? (
                                        0
                                      ) : (
                                        <BounceLoader
                                          color="white"
                                          size="20"
                                          width="10"
                                        />
                                      )}
                                    </p>
                                  </li>
                                </>
                              </ul>
                            </>
                          )}
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className="full-div space20"></div>
                <div className="row"></div>
                <div className="full-div">
                  <Modal centered show={openBid} onHide={handleClose1}>
                    <Modal.Header>
                      <Modal.Title style={{ color: "black" }}>
                        Open bid
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Price
                      <InputGroup className="mb-3">
                        <InputGroup.Text
                          style={{
                            width: "60px",
                            height: "38px",
                            marginTop: "0px",
                          }}
                        >
                          Min
                        </InputGroup.Text>
                        <FormControl
                          className="black"
                          placeholder="Amount"
                          type="number"
                          onChange={(e) => {
                            const regex =
                              /^[0-9]{0,9}(\.[0-9]{1,5})?$|^(100)(\.[0]{1,4})?$/;
                            const regexForZeros = /^00\d*/;
                            setZerosError(false);
                            if (regexForZeros.test(e.target.value)) {
                              setZerosError(true);
                            }

                            if (e.target.value && e.target.value > 100000000) {
                              setInputAmount(toInteger(e.target.value / 10));
                            } else {
                              let numa = e.target.value?.toString().split(".");
                              if (numa[1]?.length > 4) {
                                setInputAmount(ParseFloat(e.target.value, 4));
                              } else {
                                setInputAmount(e.target.value);
                              }
                            }
                          }}
                          value={inputAmount}
                          aria-label="Amount (to the nearest dollar)"
                        />
                        <InputGroup.Text
                          style={{
                            width: "60px",
                            height: "38px",
                            marginTop: "0px",
                          }}
                        >
                          Max
                        </InputGroup.Text>
                        <FormControl
                          className="black"
                          placeholder="Amount"
                          type="number"
                          onChange={(e) => {
                            {
                              const regexForZeros = /^00\d*/;
                              setZerosError1(false);
                              if (regexForZeros.test(e.target.value)) {
                                setZerosError1(true);
                              }

                              if (
                                e.target.value &&
                                e.target.value > 100000000
                              ) {
                                setMaxInputAmount(
                                  toInteger(e.target.value / 10),
                                );
                              } else {
                                let numa = e.target.value
                                  ?.toString()
                                  .split(".");
                                if (numa[1]?.length > 4) {
                                  setMaxInputAmount(
                                    ParseFloat(e.target.value, 4),
                                  );
                                } else {
                                  setMaxInputAmount(e.target.value);
                                }
                              }
                            }
                          }}
                          value={maxInputAmount}
                          aria-label="Amount (to the nearest dollar)"
                        />
                      </InputGroup>
                      {amountCheck1 && (
                        <span
                          style={{
                            color: "red",
                            wordBreak: "break-word",
                            display: "inline-block",
                          }}
                        >
                          Max value must be greater than Min value
                        </span>
                      )}
                      {amountCheck2 && (
                        <span
                          style={{
                            color: "red",
                            wordBreak: "break-word",
                            display: "inline-block",
                          }}
                        >
                          Max value must be lower than available balance
                        </span>
                      )}
                      {amountCheck4 && (
                        <span
                          style={{
                            color: "red",
                            wordBreak: "break-word",
                            display: "inline-block",
                          }}
                        >
                          Min Values cannot be negative
                        </span>
                      )}
                      {amountcheck6 && (
                        <span
                          style={{
                            color: "red",
                            wordBreak: "break-word",
                            display: "inline-block",
                          }}
                        >
                          Max Values cannot be negative
                        </span>
                      )}
                      {equalcheck && (
                        <span
                          style={{
                            color: "red",
                            wordBreak: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {" "}
                          Values cannot be equal{" "}
                        </span>
                      )}
                      {zerosError && (
                        <span
                          style={{
                            color: "red",
                            wordBreak: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {" "}
                          Min value must be valid{" "}
                        </span>
                      )}
                      {zerosError1 && (
                        <span
                          style={{
                            color: "red",
                            wordBreak: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {" "}
                          Max value must be valid{" "}
                        </span>
                      )}
                      <div className="bidDates row">
                        <div className="bidDat col-md-6">
                          <h6 style={{ color: "#000" }}>Start Date</h6>
                          <DatePicker
                            className="dateInput darker form-control"
                            selected={startDate}
                            onChange={(date) => startDateFunc(date)}
                          />
                        </div>
                        <div className="bidDat col-md-6">
                          <h6 style={{ color: "#000" }}>End Date</h6>
                          <DatePicker
                            className="dateInput darker form-control"
                            selected={endDate}
                            onChange={(date) => endDateFunc(date)}
                          />
                        </div>
                        {bidError && (
                          <span
                            style={{
                              color: "red",
                              display: "inline-block",
                              float: "left",
                              width: "50%",
                            }}
                          >
                            Can't use past date for start date.
                          </span>
                        )}
                        {bidError1 && (
                          <span
                            style={{
                              color: "red",
                              display: "inline-block",
                              float: "right",
                              width: "50%",
                            }}
                          >
                            Can't use past date for end date.
                          </span>
                        )}
                        {bidError2 && (
                          <span
                            style={{
                              color: "red",
                              display: "inline-block",
                              float: "right",
                              width: "50%",
                            }}
                          >
                            Start date is bigger than end date
                          </span>
                        )}
                      </div>
                      {amountCheck && (
                        <span
                          style={{
                            color: "red",
                            wordBreak: "break-word",
                            display: "inline-block",
                            float: "left",
                            width: "50%",
                          }}
                        >
                          Min value must be lower than available balance
                        </span>
                      )}
                    </Modal.Body>
                    <Modal.Body>
                      Available balance: {WRLCBalance} ETH
                    </Modal.Body>

                    <Modal.Body className="space-lbl">
                      <Form>
                        {["checkbox"].map((type) => (
                          <div key={`inline-${type}`} className="mb-0">
                            <Form.Check
                              inline
                              label="By checking this, I agree Dream Land Terms of Service"
                              name="group1"
                              style={{ marginBottom: "0px" }}
                              type={type}
                              checked={isSwitchOn}
                              id={`inline-${type}-1`}
                              onChange={() => {
                                switchStatus();
                              }}
                            />
                          </div>
                        ))}
                      </Form>
                    </Modal.Body>

                    <Modal.Footer>
                      {!bidInProgress ? (
                        <>
                          {amountCheck3 &&
                          isSwitchOn &&
                          endDate &&
                          startDate &&
                          !bidError &&
                          !bidError1 &&
                          !bidError2 &&
                          !bidError2 ? (
                            <a
                              // variant="primary"
                              href="javascript:void(0);"
                              className="reg-btn w-100"
                              disabled={!isSwitchOn}
                              onClick={openBidding}
                            >
                              Open bid
                            </a>
                          ) : (
                            <a
                              // variant="primary"
                              href="javascript:void(0);"
                              className="reg-btn w-100"
                              disabled={true}
                              // onClick={ openBidding}
                            >
                              Open bid
                            </a>
                          )}
                        </>
                      ) : (
                        <a className="reg-btn w-100" disabled>
                          <PulseLoader color="white" size="11" />
                        </a>
                      )}
                      <a
                        href="javascript:void(0);"
                        className="reg-btn w-100"
                        onClick={handleClose1}
                      >
                        Close
                      </a>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="full-div history-btn-cntnr">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          setDetails(false);
                          setHistoryMenu(true);
                          setAbout(false);
                        }}
                        className="w-100 reg-btn trans brdr-rad"
                      >
                        History
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          setDetails(true);
                          setHistoryMenu(false);
                          setAbout(false);
                        }}
                        className="w-100 reg-btn trans brdr-rad"
                      >
                        Details
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          setDetails(false);
                          setHistoryMenu(false);
                          setAbout(true);
                        }}
                        className="w-100 reg-btn trans brdr-rad"
                      >
                        About
                      </a>
                    </div>
                  </div>
                </div>
                <div className="description-details-pnl full-div">
                  <div
                    style={{ position: "relative" }}
                    className="description-details-head full-div"
                  >
                    {historymenu && (
                      <div className="col-lg-12">
                        <p className="clrd" style={{ cursor: "pointer" }}>
                          <b style={{ cursor: "initial" }}>Owner Address: </b>
                          <a
                            onClick={() => {
                              localStorage.clear();
                              history.push(
                                myData?.ownerAddress?.toLocaleLowerCase() ===
                                  WalletAddress?.toLocaleLowerCase()
                                  ? `/myprofile`
                                  : `/profile/${myData?.ownerAddress}`,
                              );
                            }}
                            href="javascript:void(0);"
                            style={{ color: "#E250E5" }}
                          >
                            {myData?.ownerAddress}
                          </a>
                        </p>
                        <p className="clrd">
                          <b>Owner Name: </b>
                          <a
                            onClick={() =>
                              history.push(
                                myData?.ownerAddress?.toLocaleLowerCase() ===
                                  WalletAddress?.toLocaleLowerCase()
                                  ? `/myprofile`
                                  : `/profile/${myData?.ownerAddress}`,
                              )
                            }
                            href="javascript:void(0);"
                            style={{ color: "#E250E5" }}
                          >
                            {myData?.ownerName ? myData?.ownerName : "Unnamed"}
                          </a>
                        </p>
                        <p
                          onClick={() =>
                            history.push(
                              myData?.creatorAddress?.toLocaleLowerCase() ===
                                WalletAddress?.toLocaleLowerCase()
                                ? `/myprofile`
                                : `/profile/${myData?.creatorAddress}`,
                            )
                          }
                          className="clrd"
                        >
                          <b>Creator Address:</b>{" "}
                          <a
                            href="javascript:void(0);"
                            style={{ color: "#E250E5" }}
                          >
                            {myData?.creatorAddress}
                          </a>
                        </p>
                        <p
                          onClick={() =>
                            history.push(
                              myData?.creatorAddress?.toLocaleLowerCase() ===
                                WalletAddress?.toLocaleLowerCase()
                                ? `/myprofile`
                                : `/profile/${myData?.creatorAddress}`,
                            )
                          }
                          className="clrd"
                        >
                          <b>Creator Name:</b>{" "}
                          <a
                            href="javascript:void(0);"
                            style={{ color: "#E250E5" }}
                          >
                            {" "}
                            {myData?.creatorName
                              ? myData?.creatorName
                              : "Unnamed"}{" "}
                          </a>
                        </p>
                      </div>
                    )}
                    {details && (
                      <div className="col-lg-12">
                        <p className="clrd" style={{ cursor: "pointer" }}>
                          <b style={{ cursor: "initial" }}>Collection: </b>
                          <a
                            onClick={() => {
                              history.push(
                                `/nftsbycollections/${myData?.collectionId}`,
                              );
                            }}
                            href="javascript:void(0);"
                            style={{ color: "#E250E5" }}
                          >
                            {myData?.collectionName}
                          </a>
                        </p>
                        <p className="clrd">
                          <b>Category: </b>
                          {myData?.collectionCategory}
                        </p>
                        <p className="clrd">
                          <b>Blockchain :</b> {myData?.blockChainName}
                        </p>
                      </div>
                    )}
                    {aboutmenu && (
                      <div id="zero3" className="onStep fadeIn">
                        <div className="col-lg-12">
                          <div style={{ color: "white" }}>
                            <p>
                              <ReactReadMoreReadLess
                                charLimit={50}
                                readMoreText={"Read more ▼"}
                                readLessText={"Read less ▲"}
                              >
                                {myData?.description}
                              </ReactReadMoreReadLess>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div id="zero3" className="onStep fadeIn"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container p-t-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="items_filter">
                  <ul className="de_nav de_nav">
                    {/* <li id="Mainbtn1">
                      <span onClick={handleBtnClick1} className="active">Listing Offers</span>
                    </li>
                    <li id="Mainbtn" >
                      <span onClick={handleBtnClick}>Activity</span>
                    </li> */}

                    <li
                      id="Mainbtn1"
                      className={`${
                        activeTab === USER_NFT_DETAILS_TABS.LISTING_OFFER_TAB
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        setActiveTab(USER_NFT_DETAILS_TABS.LISTING_OFFER_TAB);
                      }}
                    >
                      <span>Listing Offers</span>
                    </li>

                    <li
                      id="Mainbtn1"
                      className={`tab ${
                        activeTab === USER_NFT_DETAILS_TABS.ACTIVITY_HISTORY
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        showActivityHistory();
                        setActiveTab(USER_NFT_DETAILS_TABS.ACTIVITY_HISTORY);
                      }}
                    >
                      <span>Activity</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {activeTab == USER_NFT_DETAILS_TABS.LISTING_OFFER_TAB && (
              <InfiniteScroll
                dataLength={getOfferListingData?.length ?? 0}
                next={showListingOffers}
                hasMore={totalSimpleOffers > offerPerPage}
                loader={
                  <div className="col-sm-12 d-flex justify-content-center">
                    <ScaleLoader
                      color={WHITE_COLOR}
                      size={LOADER_MEDIUM_TWENTY_SIZE}
                    />
                  </div>
                }
              >
                <ul ul className="user-visitor-list">
                  {getOfferListingData?.length ? (
                    getOfferListingData
                      ?.slice()
                      ?.reverse()
                      ?.map((data, index) => {
                        return (
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                              <div className="col-lg-8 col-md-8 col-sm-12">
                                <div
                                  className="activity-pnl"
                                  id="get-offer-list"
                                >
                                  <div className="left-pnl">
                                    <div className="img-pnl">
                                      <img
                                        src={
                                          data?.profileImage
                                            ? data?.profileImage
                                            : IMAGE_NOT_FOUND
                                        }
                                      />
                                    </div>
                                    <div
                                      className="txt-pnl mt-2"
                                      id="text-info"
                                    >
                                      <p> {data?.username}</p>
                                      <h6> {data?.address}</h6>
                                      <p>
                                        Offer Price :{" "}
                                        {parseFloat(data?.offerPrice) / 1e18}{" "}
                                        ETH
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-pnl">
                                    {myData?.ownerAddress?.toLocaleLowerCase() ===
                                      WalletAddress?.toLocaleLowerCase() && (
                                      <button
                                        className="reg-btn w-100"
                                        disabled={isAcceptOffer === index}
                                        onClick={() => {
                                          handleAcceptOffer(
                                            data?.address,
                                            data?.offerPrice,
                                            index,
                                          );
                                          // zuni
                                        }}
                                      >
                                        {isAcceptOffer === index ? (
                                          <PulseLoader
                                            color={WHITE_COLOR}
                                            size={LOADER_SMALL_SIZE}
                                          />
                                        ) : (
                                          "Accept Offer"
                                        )}
                                      </button>
                                    )}

                                    {data?.address?.toLocaleLowerCase() ==
                                      WalletAddress?.toLocaleLowerCase() && (
                                      <button
                                        className="reg-btn w-100"
                                        disabled={isCancelListing}
                                        onClick={() => {
                                          handleCancelOffer(
                                            data?.address,
                                            data?.offerPrice,
                                          );
                                          setIsCancelListing(true);
                                        }}
                                      >
                                        {isCancelListing ? (
                                          <PulseLoader
                                            color={WHITE_COLOR}
                                            size={LOADER_SMALL_SIZE}
                                          />
                                        ) : (
                                          "Cancel Offer"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <span className="text-white"> No Offer added so far</span>
                  )}
                </ul>
              </InfiniteScroll>
            )}

            {activeTab == USER_NFT_DETAILS_TABS.ACTIVITY_HISTORY && (
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-12">
                    <InfiniteScroll
                      dataLength={nftHistory?.length ?? 0}
                      next={showMoreNFTHistory}
                      hasMore={nftHistory?.length >= pageSize}
                      loader={
                        <div className="col-sm-12 d-flex justify-content-center">
                          <ScaleLoader
                            color={WHITE_COLOR}
                            size={LOADER_MEDIUM_TWENTY_SIZE}
                          />
                        </div>
                      }
                    >
                      {nftHistory ? (
                        nftHistory?.map((data) => (
                          <>
                            <div className="activity-pnl">
                              <div className="left-pnl">
                                <div className="img-pnl">
                                  {data?.nftImage
                                    ?.toLowerCase()
                                    ?.includes(MP4) ? (
                                    <video
                                      style={{ width: "100%", height: "100%" }}
                                      src={`${data?.nftImage}`}
                                      controls
                                      currentTime={11.3}
                                    />
                                  ) : (
                                    <img src={`${data?.nftImage}`} />
                                  )}
                                </div>
                                <div className="txt-pnl d-flex flex-column justify-content-center align-items-start">
                                  <p>
                                    {data?.nftHistoryType == "OfferAcceptBid"
                                      ? "Offer Acccepted"
                                      : data?.nftHistoryType == "Cancel"
                                      ? "De-Listed"
                                      : data?.nftHistoryType == "IsMakeOffer"
                                      ? "Offer Placed"
                                      : data?.nftHistoryType ==
                                        "OfferAcceptBid "
                                      ? "Offer Accepted"
                                      : data?.nftHistoryType}{" "}
                                    #{data?.nftId} for
                                    {" " + data?.nftPrice} ETH
                                  </p>
                                  <span>
                                    {moment(
                                      data?.createdAt?.split("T")[0],
                                    ).format("Do [of] MMMM, YYYY")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <></>
                      )}
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            )}
          </section>

          <Modal centered size="sm" show={filterTrigger} onHide={sellingModal}>
            <Modal.Body>
              {" "}
              <div className="row">
                <div className="col-md-12">
                  <form
                    name="contactForm"
                    id="contact_form"
                    className="form-border"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="field-set">
                              <h4 className="text-dark text-center mb-4">
                                List for Sale
                              </h4>
                              <label
                                style={{
                                  color: "#000000",
                                  textAlign: "center",
                                  marginLeft: "40px",
                                }}
                              >
                                Selling Amount in{" "}
                                {isSellBargain ? "ETH" : "ETH"}
                              </label>
                              <FormControl
                                className="black"
                                placeholder="Amount"
                                type="number"
                                onChange={(e) => {
                                  handleSellValidatio(e.target.value);
                                }}
                                value={NewPrice}
                                aria-label="Amount (to the nearest dollar)"
                              />
                            </div>
                          </div>
                        </div>
                        {maxpriceofsell ? (
                          <p style={{ color: "red" }}>
                            Price must be greater than 0.00000001
                          </p>
                        ) : sellnftpriceerror ? (
                          <p style={{ color: "red" }}>
                            Price Cannot be negetive
                          </p>
                        ) : (
                          ""
                        )}
                        {maxpriceofsell ? (
                          <p style={{ color: "red" }}>
                            <></>
                          </p>
                        ) : sellnftpriceerror1 ? (
                          <p style={{ color: "red" }}>Price Cannot be zero</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        id="submit"
                        className=" d-flex justify-content-center align-items-center"
                      >
                        {selValid ? (
                          <button
                            className="btn-main"
                            style={{ opacity: "0.6" }}
                            disabled
                          >
                            Complete Listing
                          </button>
                        ) : (
                          <button
                            className="btn-main"
                            onClick={() => {
                              if (isSellBargain) {
                                setSellingIsLoading(true);
                                openBidding();
                              } else {
                                sellingHandler();
                              }
                            }}
                          >
                            {sellingIsLoading ? (
                              <PulseLoader color="white" size="11" />
                            ) : (
                              "Complete Listing"
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal centered show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title style={{ color: "black" }}>Place Bid</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              Price
              <InputGroup className="mb-3">
                <InputGroup.Text className="h-100">ETH</InputGroup.Text>
                <FormControl
                  className="black"
                  placeholder="Amount"
                  type="number"
                  value={Makeofferinput}
                  disabled={
                    WRLCBalance <= myData?.bidInitialMinimumAmount ? true : ""
                  }
                  onChange={(e) => {
                    if (e.target.value && e.target.value > 100000000) {
                      setMakeofferinput(toInteger(e.target.value / 10));
                      // value=
                    } else {
                      let numa = e.target.value?.toString().split(".");
                      if (numa[1]?.length > 4) {
                        setMakeofferinput(ParseFloat(e.target.value, 4));
                        //  value=
                      } else {
                        setMakeofferinput(parseFloat(e.target.value));
                      }
                    }
                  }}
                  aria-label="Amount (to the nearest dollar)"
                />
              </InputGroup>
              <div className="bidDat">
                <h6 style={{ color: "#000" }}>Expiry date</h6>
                <DatePicker
                  className="dateInput"
                  minDate={minndate}
                  selected={expiryDateplacebid}
                  onChange={(date) => setexpiryDateplacebid(date)}
                />
              </div>
              {myData?.bidInitialMinimumAmount > WRLCBalance ? (
                <span
                  style={{
                    color: "red",
                    display: "inline-block",
                    wordBreak: "break-word",
                    float: "left",
                    width: "55%",
                  }}
                >
                  Available balance is lower than the minimun bid
                </span>
              ) : (
                <></>
              )}
              {Makeofferinput > WRLCBalance && (
                <p style={{ color: "red", wordBreak: "break-word" }}>
                  Input value must be lower than available balance
                </p>
              )}
              {Makeofferinput > myData?.bidInitialMaximumAmount && (
                <p style={{ color: "red", wordBreak: "break-word" }}>
                  Input value must be lower than or equal to max bidding price
                </p>
              )}
              {negetive && (
                <p style={{ color: "red", wordBreak: "break-word" }}>
                  Price Cannot be Negative
                </p>
              )}{" "}
              {negetive1 && (
                <p style={{ color: "red", wordBreak: "break-word" }}>
                  Price Cannot be zero
                </p>
              )}
              {minbalance && (
                <p style={{ color: "red", wordBreak: "break-word" }}>
                  Input value must be greater from minimum bid price
                </p>
              )}
              {Makeofferinput <= myData?.bidInitialMinimumAmount && (
                <p style={{ color: "red", wordBreak: "break-word" }}>
                  Input value must be greater then minimum bid price
                </p>
              )}
            </Modal.Body>

            <Modal.Body>
              Min bidding price: {myData?.bidInitialMinimumAmount} ETH
            </Modal.Body>

            <Modal.Body>
              Max bidding price: {myData?.bidInitialMaximumAmount} ETH
            </Modal.Body>

            <Modal.Body>Available balance: {WRLCBalance} ETH</Modal.Body>

            <Modal.Body>
              <Form className="space-lbl">
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="By checking this, I agree Midnight Terms of Service "
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      checked={isSwitchOn}
                      onChange={() => {
                        switchStatus();
                      }}
                    />
                  </div>
                ))}
              </Form>
            </Modal.Body>

            <Modal.Footer>
              {expiryError ? (
                <>
                  <a
                    className="reg-btn w-100"
                    disabled={true}
                    style={{ opacity: 0.6 }}
                  >
                    Make Offer
                  </a>
                </>
              ) : (
                <>
                  {!isOfferInProgress ? (
                    <>
                      {makeofferbutton && isSwitchOn && expiryDateplacebid ? (
                        <a
                          // variant="primary"
                          className="reg-btn w-100"
                          disabled={!isSwitchOn}
                          onClick={postBidding}
                        >
                          Make Offer
                        </a>
                      ) : (
                        <a className="reg-btn w-100" disabled={true}>
                          Make Offer
                        </a>
                      )}
                    </>
                  ) : (
                    <a className="reg-btn w-100" disabled={true}>
                      <PulseLoader color="white" size="11" />
                    </a>
                  )}
                </>
              )}
              <a className="reg-btn w-100" onClick={handleClose}>
                Close
              </a>
            </Modal.Footer>
          </Modal>

          <Modal centered show={imageShow} onHide={handleImageClose}>
            <Modal.Header>
              <Modal.Title style={{ color: "black" }}>
                <img
                  src={myData?.image}
                  className="img-fluid img-rounded mb-sm-30"
                  alt="NFT.png"
                />
              </Modal.Title>
            </Modal.Header>
          </Modal>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
          <Footer />

          <Modal
            centered
            size="sm"
            show={deleteNFTModalShow}
            onHide={() => {
              setDeleteNFTModalShow(false);
            }}
          >
            <Modal.Body>
              {" "}
              <div className="row">
                <div className="col-md-12">
                  <h3 style={{ color: "black" }}>Alert!</h3>
                  <p>Are you sure you want to delete NFT</p>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 20,
                    }}
                  >
                    <a
                      className="reg-btn w-45"
                      onClick={() => {
                        setDeleteNFTModalShow(false);
                      }}
                    >
                      Cancel
                    </a>
                    <a
                      className="reg-btn w-45"
                      onClick={() => {
                        setDeleteNFTModalShow(false);
                        axios
                          .delete(
                            httpUrl +
                              `/api/v1/Nft/DeleteNft?NftId=${myData?.id}`,
                          )
                          .then((resp) => {
                            toast.success(`NFT Deleted successfully`, {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });

                            setTimeout(() => {
                              history.push("/myprofile");
                            }, 3000);
                          });
                      }}
                    >
                      Confirm
                    </a>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            centered
            size="sm"
            show={giveMakeOfferValueModalShow}
            onHide={() => {
              setGiveMakeOfferValueModalShow(false);
            }}
          >
            <Modal.Body>
              {" "}
              <div className="row">
                <div className="col-md-12">
                  <form
                    name="contactForm"
                    id="contact_form"
                    className="form-border"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="field-set">
                              <label
                                style={{
                                  color: "#000000",
                                  textAlign: "center",
                                  marginLeft: "40px",
                                }}
                              >
                                Make Offer in ETH:
                              </label>
                              <FormControl
                                className="black"
                                placeholder="Amount"
                                type="number"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  setMakeOfferPrice(value);
                                  if (value > 0 && value <= myData?.sellPrice) {
                                    setMakeOfferPriceValidation(true);
                                  } else {
                                    setMakeOfferPriceValidation(false);
                                  }
                                }}
                                value={makeOfferPrice}
                                aria-label="Amount (to the nearest dollar)"
                              />

                              <p
                                style={{
                                  marginBottom: 3,
                                  color:
                                    makeOfferPrice > myData?.sellPrice
                                      ? "red"
                                      : "black",
                                  fontSize: 14,
                                }}
                              >
                                Max Offer value {myData?.sellPrice} ETH
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div id="submit" className="pull-left">
                        {!makeOfferPriceValidation ? (
                          <button
                            className="btn-main"
                            style={{ opacity: "0.6", marginLeft: "45px" }}
                            disabled
                          >
                            Make Offer
                          </button>
                        ) : (
                          <button
                            style={{ marginLeft: "45px" }}
                            className="btn-main"
                            onClick={() => {
                              if (WRLCBalance < makeOfferPrice) {
                                toast.error(`You don't have enough ETH`);
                                return;
                              }
                              setMakeOfferLoading(true);
                              postBidding();
                            }}
                          >
                            {makeOfferLoading ? (
                              <PulseLoader
                                color={WHITE_COLOR}
                                size={LOADER_SMALL_SIZE}
                              />
                            ) : (
                              "Make Offer"
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};

export default UserNftDetails;

const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));
