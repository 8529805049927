import http from "../http";
const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;

export default class GetMyAllCollectionFilter {
  static GetMyAllCollectionFilterApi(body) {
    return http.post(
      httpUrl +
        `/api/v1/Nft/${
          body.isMine ? "GetMyAllCollections" : "GetAllCollectionsNftHolder"
        }?pageSize=10000`,
      body.body,
    );
  }
}
