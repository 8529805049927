import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.jsx";
import { createGlobalStyle } from "styled-components";
import http from "../../Redux/Api/http";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Web3 from "web3";
import { PulseLoader } from "react-spinners";
import { Modal, Button } from "react-bootstrap";

const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;

const ImportNFT = function () {
  const [contractAddress, setContractAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    contractAddress: "",
  });

  useEffect(() => {
    if (!Web3.utils.isAddress(contractAddress) && contractAddress) {
      setErrorMessage((prev) => {
        return { ...prev, contractAddress: "Invalid contract address" };
      });
    } else {
      setErrorMessage((prev) => {
        return { ...prev, contractAddress: "" };
      });
    }
  }, [contractAddress]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12  col-sm-12">
                <div className="small-header">
                  <div className="bg-layer"></div>
                  <span className="drop-span"></span>
                  <h1 className="text-center">Import Collection</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="container-fluid inner-page"
        style={{ marginBottom: "200px" }}
      >
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                <h5 class="txt-dark">Contract Address</h5>
                <input
                  onChange={(e) => {
                    setContractAddress(e.target.value);
                  }}
                  name="contractAddress"
                  autoComplete="off"
                  className="form-control"
                  placeholder="Contract Address"
                />
                <p style={{ color: "red" }}>{errorMessage.contractAddress}</p>

                {!isLoading ? (
                  <button
                    id="btnBuy"
                    className=" btn-main mt-3"
                    onClick={() => {
                      if (errorMessage.contractAddress) {
                        return;
                      }

                      if (!contractAddress) {
                        setErrorMessage((prev) => {
                          return {
                            ...prev,
                            contractAddress: "Please enter contract address",
                          };
                        });
                        return;
                      }

                      const data = new FormData();
                      data.append("ContractAddress", contractAddress);
                      setIsLoading(true);
                      http
                        .post(httpUrl + "/ipfs/ImportNft", data, {
                          timeout: 300000,
                        })
                        .then((resp) => {
                          setIsLoading(false);
                          console.log("kajdbcfndv   ::   ", resp.data);

                          if (resp.data.isSuccess) {
                            setSuccessModalShow(true);
                          } else {
                            toast.error(resp.data?.error?.errorMessage, {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                        })
                        .catch((err) => {
                          toast.error(`Something went wrong`, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                          setIsLoading(false);
                        });
                    }}
                  >
                    Import Collection
                  </button>
                ) : (
                  <button id="submit" className="btn-main mt-3">
                    <PulseLoader color="white" size="11" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <Modal
        centered
        show={successModalShow}
        // onHide={handleImageClose}
        className="import-modal"
      >
        <Modal.Body>
          <h2 className="text-center" style={{ color: "black" }}>
            Success
          </h2>
          <p
            className="text-center"
            style={{ fontWeight: "500", fontSize: 18 }}
          >
            Your NFT's will reflect soon.
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              id="submit"
              className="btn-main mt-3"
              onClick={() => {
                setSuccessModalShow(false);
                history.push("/myprofile");
              }}
            >
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImportNFT;
