import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import metamask from "../../assets/images/metamask.png";
import Footer from "../components/Footer.jsx";
import { createGlobalStyle } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PulseLoader } from "react-spinners";
import connectMetaMaskaction from "../../Redux/Actions/WalletActions/WalletAction";
import AuthConnectAction from "../../Redux/Actions/AuthActions/AuthConnectAction";
import ValidateSignatureAction from "../../Redux/Actions/AuthActions/ValidateSignatureAction";
import { ToastContainer, toast } from "react-toastify";
import lottie from "lottie-web";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";

import connectionAnimation from "../../assets/animation/connection/data.json";
import { WalletDisconnect } from "../../Redux/Actions/WalletActions/WalletAction";
import { CHAIN_ID, CHAIN_ID_COMPARE, NETWORK_NAME } from "../../constants.js";
function ConnectWallet() {
  const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    
  }
  .box-login p{
    color: #a2a2a2 !important;
  }
  .box-login{
    border-radius: 3px;
    padding: 40px 50px;
  }
`;

  const history = useHistory();
  const dispatch = useDispatch();
  const AuthConnectState = useSelector((state) => state.AuthConnect);
  const AuthConnect = AuthConnectState?.AuthConnectResponse?.data;
  const isConnected = useSelector(
    (state) => state.Login?.authResponse?.data?.token,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [chain, setChain] = useState(false);
  const [unlocked, setUnlocked] = useState(false);
  const dispatchConnect = () => dispatch(connectMetaMaskaction());
  var isUserLogedIn = false;
  const User = useSelector((state) => state.Login);
  const Tokenn = User.authResponse?.data?.token;
  const GetAllBlockChain = useSelector(
    (state) => state?.GetAllBlockChain?.GetAllBlockChainResponse?.data,
  );

  if (
    User.authResponse &&
    User.authResponse.data &&
    User.authResponse?.data?.token
  ) {
    isUserLogedIn = true;
  } else {
    isUserLogedIn = false;
  }

  const connnectwallet = async () => {
    try {
      setIsLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
  
      // Get the current network
      const network = await provider.getNetwork();
      const currentChainId = network.chainId;
  
      // Target chain ID for BSC Testnet (0x61 in hex is 97 in decimal)
      const targetChainId = CHAIN_ID;
  
      if (currentChainId !== targetChainId) {
        console.log('Switching network...');
  
        // Request to add or switch to the BSC Testnet
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: CHAIN_ID,
              chainName: "Base Sepolia",
              rpcUrls: ["https://sepolia.base.org"],
              blockExplorerUrls: ["https://sepolia.basescan.org"],
              nativeCurrency: {
                symbol: "ETH",
                decimals: 18,
              },
            },
          ],
        });
  
        // Verify if the network switched successfully
        const updatedNetwork = await provider.getNetwork();
        console.log('updatedNetwork',updatedNetwork, CHAIN_ID_COMPARE, updatedNetwork == CHAIN_ID_COMPARE);
        if (updatedNetwork.chainId !== CHAIN_ID_COMPARE) {
          setIsLoading(false);
          return toast.error(`Please switch to the ${NETWORK_NAME}`, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      }
  
      setTrigger(true);
  
      // Connect to the wallet if everything is in place
      if (window.ethereum) {
        console.log('window.ethereum',window.ethereum);
        await window.ethereum.enable();
        await dispatchConnect()
          .then((res) => {
            console.log('res',res);
            connectionFunc(res);
          })
          .catch((e) => {
            console.log('eee',e);

            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
        toast.error("Please install the MetaMask extension", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (e) {
      setIsLoading(false);
  
      if (e.code === -32002) {
        toast.error("MetaMask connection is already processing", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        console.error("Error connecting wallet:", e);
      }
    }
  };
  
  

  const connectionFunc = async (res) => {
    await dispatch(AuthConnectAction({ address: res }))
      .then(async (response) => {
        // console.log(response);
        // setTimeout(async () => {
        console.log('Tokenn',Tokenn);
        if (response?.data?.message && !Tokenn) {
          await signMessage(response?.data?.message);
          setTrigger(false);
        }
        // }, 1000);
      })
      .catch((error) => {
        if (error.code == 4001 || error.status == 500) {
          toast.error(error.message.split(":")[1], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading(false);
        }
        // setIsLoading(false)
      });
  };

  const checkMetamask = async () => {
    let isUnlocked = await window.ethereum._metamask?.isUnlocked();
    if (isUnlocked) {
      setUnlocked(true);
    } else {
      setUnlocked(false);
      dispatch(WalletDisconnect());
    }
  };
  useEffect(() => {
    checkMetamask();
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#connectionCircle"),
      animationData: connectionAnimation,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    });
  }, []);

  const signMessage = async (message) => {
    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    const address = await signer.getAddress();

    await dispatch(
      ValidateSignatureAction({
        address: address,
        signature: signature,
      }),
    )
      .then((res) => {
        toast.success(
          `${res.message} you are going to redirect to profile page`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );

        setTimeout(() => {
          history.push("/myprofile");
        }, 3000);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div>
      <GlobalStyles />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />

      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12  col-sm-12">
                <div className="small-header">
                  <div className="bg-layer"></div>
                  <span className="drop-span"></span>
                  <h1>Connect Wallet</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="offset-lg-4 col-lg-4 col-md-6 offset-md-3 col-sm-12">
                <div className="text-center">
                  <div className="wallet-connect-pnl active">
                    <img src={metamask} />
                    <h5>Meta Mask</h5>
                    <p className="mb-2">Connect with {NETWORK_NAME}!</p>
                    {isLoading ? (
                      <div className="w-100">
                        <button className="reg-btn">
                          <PulseLoader color="white" size="11" />
                        </button>
                      </div>
                    ) : (
                      <div className="field-set">
                        <input
                          type="submit"
                          id="send_message"
                          value={
                            unlocked &&
                              isConnected &&
                              window.web3.currentProvider.networkVersion == "2611"
                              ? "Connected"
                              : "Connect Wallet"
                          }
                          className="reg-btn"
                          onClick={connnectwallet}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ConnectWallet;
