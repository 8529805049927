import React, { useEffect, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import MyNft from "./MyNfts/MyNfts";
import Footer from "../components/Footer.jsx";
import bannerimg from "../../assets/images/profile-banner.png";
import { createGlobalStyle } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import bar from "../../assets/images/bar.png";
import { FaGlobe, FaInstagram, FaTwitter } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import MyCollections from "./MyCollections";
import hearticon from "../../assets/images/icon-heart.png";
import nfticon from "../../assets/images/nft-icon.png";
import collectionicon from "../../assets/images/collection-icon.png";
import GetMyAllCollectionsAction from "../../Redux/Actions/CollectionAction/GetMyAllCollections";
import AllFavourite from "./AllFavorite/AllFavourite";
import { WalletDisconnect } from "../../Redux/Actions/WalletActions/WalletAction";
import { AuthConnectRequest } from "../../Redux/Actions/AuthActions/AuthConnectAction";
import { LogoutAction } from "../../Redux/Actions/AuthActions/LogoutAction";
import { ValidateSignatureRequest } from "../../Redux/Actions/AuthActions/ValidateSignatureAction";
import { Dropdown, DropdownButton, SplitButton } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import http from "../../Redux/Api/http";
import moment from "moment";
import lINK from "../../assets/images/LINK.png";
import API from "../../Redux/Api";
import rlc from "../../assets/images/RLF-icon.png";
// import Slider from 'react-rangeslider'
import { SingleSlider } from "react-slider-kit";
import TestNfts from "./MyNfts/TestNfts";
import { toInteger } from "lodash";
import SyncNFT from "./MyNfts/MyNftDetail/SyncNFT";
import InfiniteScroll from "react-infinite-scroll-component";
import { ScaleLoader } from "react-spinners";
import { LOADER_MEDIUM_TWENTY_SIZE, WHITE_COLOR } from "../../constants";
const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const initialState = { isDisable: false };
const reducer = (state, action) => {
  switch (action.type) {
    case "clicked":
      return { isDisable: true };
    case "notClicked":
      return { isDisable: false };
  }
};
const GlobalStyles = createGlobalStyle`

`;
const changeImage = (event) => {
  var image = document.getElementById("output");
  image.src = URL.createObjectURL(event.target.files[0]);
};
const MyProfile = function (props) {
  const [value, setValue] = useState();
  const [value1, setValue1] = useState();
  const [route, setRoute] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [ProfileData, setprofiledata] = useState(false);
  const [nftHistory, setNftHistory] = useState();
  const history = useHistory();
  const [state, disableDispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.center) {
      handleBtnClick1();
    }
  }, [location.state]);
  const dispatch = useDispatch();
  const User = useSelector((state) => state.Login);

  const myAllCollectionsState = useSelector(
    (state) => state.GetMyAllCollections?.GetAllMyCollectionsResponse?.data,
  );

  const MyNfts = useSelector(
    (state) => state.GetMyAllNfts?.GetMyAllNftsResponse?.data,
  );

  const Logoutt = async () => {
    await dispatch(WalletDisconnect());
    await dispatch(AuthConnectRequest());
    await dispatch(LogoutAction());
    await dispatch(ValidateSignatureRequest());
  };

  const WalletAddress = useSelector(
    (state) => state.WalletConnction?.WalletResponse?.accounts,
  );
  const MyProfile = useSelector(
    (state) => state.MyProfile?.MyProfileResponse?.data,
  );

  const GetNftCollectionCategories = useSelector(
    (state) =>
      state?.GetNftCollectionCategories?.GetNftCollectionCategoriesResponse
        ?.data,
  );
  var counter = 0;
  useEffect(() => {
    setprofiledata(MyProfile);
  }, [MyProfile]);

  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenu4, setOpenMenu4] = React.useState(false);
  const [openMenu5, setOpenMenu5] = React.useState(false);
  const [openMenu6, setOpenMenu6] = React.useState(false);
  const [error, setError] = useState(false);
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [reset, setResetState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [filterState, setFilterState] = React.useState({
    topFilter: "OnSale",
    walletAddress: WalletAddress ? WalletAddress : "nill",
    pageSize: 9,
    currentPage: 1,
    buyNow: false,
    onAuctions: false,
    hasOffers: false,
    categories: [],
    min: 0,
    max: 0,
    sortBy: "desc",
    sortIndex: 1,
    search: "",
    havingMineNFTS: false,
  });

  const priceHandler = () => {
    setError(false);
    if (parseFloat(minValue) > parseFloat(maxValue)) {
      setError(true);
      return;
    }
    if (maxValue == 0) {
      setError(true);
      return;
    }
    if (minValue && maxValue && parseFloat(minValue) <= parseFloat(maxValue)) {
      setResetState(true);
      setFilterState((prev) => {
        return {
          ...prev,
          min: parseFloat(minValue),
          max: parseFloat(maxValue),
          sortIndex: 0,
        };
      });
      setError(false);
    }
  };

  const handleBtnClick = () => {
    setFilterState((prev) =>
      filterState.topFilter
        ? { ...prev, topFilter: 0 }
        : { ...prev, topFilter: 0 },
    );
    setOpenMenu(true);
    setOpenMenu1(false);
    setOpenMenu2(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    setOpenMenu5(false);
    setOpenMenu6(false);
    // document.getElementById("Mainbtn6").classList.remove("active");
    document.getElementById("Mainbtn").classList.add("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
    document.getElementById("Mainbtn3").classList.remove("active");
    document.getElementById("Mainbtn4").classList.remove("active");
    document.getElementById("Mainbtn5").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setFilterState((prev) =>
      filterState.topFilter
        ? { ...prev, topFilter: "Owner" }
        : { ...prev, topFilter: "Owner" },
    );
    setFilterState((prev) => {
      return { ...prev, buyNow: false };
    });

    setOpenMenu1(true);
    setOpenMenu2(false);
    setOpenMenu(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    setOpenMenu5(false);
    setOpenMenu6(false);
    // document.getElementById("Mainbtn6").classList.remove("active");
    document.getElementById("Mainbtn1").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
    document.getElementById("Mainbtn3").classList.remove("active");
    document.getElementById("Mainbtn4").classList.remove("active");
    document.getElementById("Mainbtn5").classList.remove("active");
  };
  const handleBtnClick2 = () => {
    setFilterState((prev) =>
      filterState.topFilter
        ? { ...prev, topFilter: "Created" }
        : { ...prev, topFilter: "Created" },
    );
    setFilterState((prev) => {
      return { ...prev, buyNow: false };
    });

    setOpenMenu2(true);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    setOpenMenu5(false);
    setOpenMenu6(false);
    // document.getElementById("Mainbtn6").classList.remove("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.add("active");
    document.getElementById("Mainbtn3").classList.remove("active");
    document.getElementById("Mainbtn4").classList.remove("active");
    document.getElementById("Mainbtn5").classList.remove("active");
  };
  const handleBtnClick3 = () => {
    setFilterState((prev) =>
      filterState.topFilter
        ? { ...prev, topFilter: "Favorite " }
        : { ...prev, topFilter: "Favorite " },
    );
    setFilterState((prev) => {
      return { ...prev, buyNow: false };
    });

    setOpenMenu2(false);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(true);
    setOpenMenu4(false);
    setOpenMenu5(false);
    setOpenMenu6(false);
    // document.getElementById("Mainbtn6").classList.remove("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
    document.getElementById("Mainbtn3").classList.add("active");
    document.getElementById("Mainbtn4").classList.remove("active");
    document.getElementById("Mainbtn5").classList.remove("active");
  };
  const handleBtnClick4 = () => {
    setOpenMenu2(false);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    setOpenMenu4(true);
    setOpenMenu5(false);
    setOpenMenu6(false);
    // document.getElementById("Mainbtn6").classList.remove("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
    document.getElementById("Mainbtn3").classList.remove("active");
    document.getElementById("Mainbtn4").classList.add("active");
    document.getElementById("Mainbtn5").classList.remove("active");
  };
  const handleBtnClick5 = () => {
    if (!nftHistory) {
      API.GetNftHistoryByAccount.GetNftHistoryByAccountApi(
        currentPage,
        pageSize,
      ).then((response) => {
        setNftHistory(response.data.data);
      });
    }
    setOpenMenu2(false);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    setOpenMenu5(true);
    setOpenMenu6(false);
    // document.getElementById("Mainbtn6").classList.remove("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
    document.getElementById("Mainbtn3").classList.remove("active");
    document.getElementById("Mainbtn4").classList.remove("active");
    document.getElementById("Mainbtn5").classList.add("active");
  };
  const handleBtnClick6 = () => {
    // if (!nftHistory) {
    //   API.GetNftHistoryByAccount.GetNftHistoryByAccountApi().then((response) => {
    //     setNftHistory(response.data.data)
    //   })
    // }
    setFilterState((prev) =>
      filterState.topFilter
        ? { ...prev, topFilter: "Sync " }
        : { ...prev, topFilter: "Sync " },
    );
    setFilterState((prev) => {
      return { ...prev, buyNow: false };
    });
    setOpenMenu2(false);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    setOpenMenu5(false);
    setOpenMenu6(true);
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
    document.getElementById("Mainbtn3").classList.remove("active");
    document.getElementById("Mainbtn4").classList.remove("active");
    document.getElementById("Mainbtn5").classList.remove("active");
    // document.getElementById("Mainbtn6").classList.add("active");
  };
  useEffect(() => {
    if (props.location.state == "bought") {
      handleBtnClick1();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("Tab") == "onsale") {
      handleBtnClick();
    } else if (localStorage.getItem("Tab") == "owned") {
      handleBtnClick1();
    } else if (localStorage.getItem("Tab") == "created") {
      handleBtnClick2();
    } else if (localStorage.getItem("Tab") == "favourites") {
      handleBtnClick3();
    } else if (localStorage.getItem("Tab") == "collections") {
      handleBtnClick4();
    } else if (localStorage.getItem("Tab") == "activity") {
      handleBtnClick4();
    } else {
      handleBtnClick();
    }
  }, []);

  const text = MyProfile?.bio ? MyProfile?.bio?.toString() : "";

  // infinite scroll bar for activity
  const showMoreNFTHistory = () => {
    let temp = pageSize + 5;
    setPageSize(temp);
    setTimeout(() => {
      API.GetNftHistoryByAccount.GetNftHistoryByAccountApi(
        currentPage,
        temp,
      ).then((response) => {
        setNftHistory(response.data.data);
      });
    }, 700);
  };
  return (
    <div className="gradient-bg-light">
      <GlobalStyles />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
      <div className="profile-banner">
        <div className="container">
          {ProfileData?.profileBannerImage && (
            <div
              style={{
                backgroundImage: `url(${ProfileData?.profileBannerImage?.replaceAll(
                  "\\",
                  "/",
                )})`,
              }}
              className="banner"
            >
              {/* <img  src={httpUrl + "/" + ProfileData?.profileBannerImage} alt="Banner images" /> */}

              <div className="share-list-pnl">
                <ul className="share-list-list">
                  <CopyToClipboard
                    text={`${PUBLIC_URL}profile/${WalletAddress}`}
                    onCopy={() => {
                      disableDispatch({ type: "clicked" });
                      toast.success("Profile copied successfully", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                      });
                      setTimeout(() => {
                        disableDispatch({ type: "notClicked" });
                      }, 5000);
                    }}
                  >
                    <li>
                      <a href="javascript:void(0);">
                        <i className="fa fa-share-alt"></i>
                      </a>
                    </li>
                  </CopyToClipboard>
                  <li>
                    <a
                      onClick={() => history.push("/settings")}
                      href="javascript:void(0);"
                    >
                      <i className="fa fa-cog"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {!ProfileData?.profileBannerImage && (
            <div
              style={{
                backgroundImage: `url(${bannerimg})`,
              }}
              className="banner"
            >
              {/* <img src={bannerimg} alt="Banner images" /> */}

              <div className="share-list-pnl">
                <ul className="share-list-list">
                  {/* <li><a href="javascript:void(0);"><i className="fa fa-share-alt"></i></a></li> */}
                  <li>
                    <a
                      onClick={() => history.push("/settings")}
                      href="javascript:void(0);"
                    >
                      <i className="fa fa-cog"></i>
                    </a>
                  </li>
                  {/* <li><a onClick={() => { handleSyncNFT()}} href="javascript:void(0);" title="Sync Scarlet NFTs"><i className="fa fa-refresh" ></i></a></li> */}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="profile-image-holder">
        <div className="img-pnl">
          {ProfileData?.profileImage ? (
            <img src={ProfileData?.profileImage} alt="profile.png" />
          ) : (
            <div style={{ color: "white" }}>
              {" "}
              <FaUserCircle size="2x" />{" "}
            </div>
          )}
          <span className="check-span">
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="text-pnl">
          <h2>{ProfileData?.username ? ProfileData?.username : "Unnamed"}</h2>

          <p id="wallet">
            {/* <span className="email-span" style={{ wordWrap: 'break-word' }}>{ProfileData?.email} </span><br /> */}
            <img
              src={rlc}
              style={{
                display: "inline-block",
                maxWidth: "20px",
                marginRight: "4px",
                marginBottom: "4px",

                // display: inline-block;
                // max-width: 20px;
                // margin-right: 4px;
              }}
            />
            {WalletAddress ? WalletAddress : ProfileData?.address}{" "}
            <CopyToClipboard
              text={WalletAddress}
              onCopy={() => {
                disableDispatch({ type: "clicked" });
                toast.success("Address copied successfully", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(() => {
                  disableDispatch({ type: "notClicked" });
                }, 5000);
              }}
            >
              <button
                id="btn_copy"
                style={{
                  background:
                    "linear-gradient(135deg, #3EAC91 0%, #3EAC91 100%)",
                  color: "white",
                  border: "none",
                }}
                title="Copy Address"
                disabled={state.isDisable}
              >
                Copy
              </button>
            </CopyToClipboard>
          </p>
          <br />
          {text && (
            <p
              id="wallet"
              className="email-span"
              style={{ wordWrap: "break-word", maxWidth: "500px" }}
            >
              {showMore ? text : `${text?.substring(0, 45)}`}
              {text?.length > 45 ? (
                <button
                  id="btn_copy"
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                    background:
                      "linear-gradient(135deg, #3EAC91 0%, #3EAC91",
                    color: "white",
                    wordBreak: "break-word",
                  }}
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? " show less" : "show more"}
                </button>
              ) : null}
            </p>
          )}
          <div className="user-social-contacts">
            {ProfileData?.instagramLink &&
            ProfileData?.instagramLink != "null" ? (
              <a
                target="blank"
                href={ProfileData?.instagramLink}
                style={{
                  cursor: "pointer",
                  color: "#E250E5",
                  marginRight: "30px",
                  fontSize: "15px",
                }}
              >
                <i className="fab fa-discord"></i>
              </a>
            ) : (
              <></>
            )}
            {ProfileData?.twitterLink && ProfileData?.twitterLink != "null" ? (
              <a
                target="blank"
                href={ProfileData?.twitterLink}
                style={{
                  cursor: "pointer",
                  color: "#E250E5",
                  marginRight: "30px",
                  fontSize: "15px",
                }}
              >
                <i className="fa fa-twitter"></i>
              </a>
            ) : (
              <></>
            )}
            {/* <a target="_blank" href={userData?.twitterLink} style={{cursor:"pointer",color:"white"}}><i className="fa fa-twitter"></i></a> */}
            {ProfileData?.yourSiteLink &&
            ProfileData?.yourSiteLink != "null" ? (
              <a
                target="blank"
                href={ProfileData?.yourSiteLink}
                style={{
                  cursor: "pointer",
                  color: "#E250E5",
                  marginRight: "30px",
                  fontSize: "15px",
                }}
              >
                <i className="fa fa-globe" aria-hidden="true"></i>
              </a>
            ) : (
              <></>
            )}

            {ProfileData?.faceBook && ProfileData?.faceBook != "null" ? (
              <a
                target="blank"
                href={ProfileData?.faceBook}
                style={{
                  cursor: "pointer",
                  color: "#E250E5",
                  marginRight: "30px",
                  fontSize: "15px",
                }}
              >
                <i className="fa fa-facebook-square" aria-hidden="true"></i>
              </a>
            ) : (
              <></>
            )}
            {/* <a target="_blank" href={userData?.yourSiteLink}  style={{cursor:"pointer",color:"white"}}><i className="fa fa-yourSiteLink"></i></a> */}
          </div>
        </div>
      </div>

      <section className="container p-t-0">
        <div className="row">
          <div className="col-lg-12">
            <div className="items_filter">
              <ul className="de_nav de_nav">
                <li id="Mainbtn" className="active">
                  <span
                    onClick={() => {
                      handleBtnClick();
                      localStorage.setItem("Tab", "onsale");
                    }}
                  >
                    {" "}
                    On Sale
                  </span>
                </li>
                <li id="Mainbtn1" className="">
                  <span
                    onClick={() => {
                      handleBtnClick1();
                      localStorage.setItem("Tab", "owned");
                    }}
                  >
                    {" "}
                    Owned
                  </span>
                </li>

                <li id="Mainbtn2" className="">
                  <span
                    onClick={() => {
                      handleBtnClick2();
                      localStorage.setItem("Tab", "created");
                    }}
                  >
                    {" "}
                    Created
                  </span>
                </li>
                <li id="Mainbtn3" className="">
                  <span
                    onClick={() => {
                      handleBtnClick3();
                      localStorage.setItem("Tab", "favourites");
                    }}
                  >
                    {" "}
                    Favourites
                  </span>
                </li>
                <li id="Mainbtn4" className="">
                  <span
                    onClick={() => {
                      handleBtnClick4();
                      localStorage.setItem("Tab", "collections");
                    }}
                  >
                    {" "}
                    Collections
                  </span>
                </li>
                <li id="Mainbtn5" className="">
                  <span
                    onClick={() => {
                      handleBtnClick5();
                      localStorage.setItem("Tab", "activity");
                    }}
                  >
                    {" "}
                    Activity
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="tab-container full-div">
          {/* Side Filter */}
          {(openMenu || openMenu1 || openMenu2 || openMenu3 || openMenu4) && (
            <div className="side-filter-bar">
              <div className="filter-head-pnl">
                <h5>Filters</h5>
                <i className="fa fa-filter"></i>
              </div>
              <div className="filter-body-pnl">
                <Accordion>
                  <Card>
                    <div className="accordion" id="myAccordion">
                      {(openMenu || openMenu1 || openMenu2 || openMenu3) && (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              type="button"
                              className="accordion-button collapsed"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                            >
                              Status
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse"
                          >
                            <div className="card-body">
                              <form>
                                <div className="form-check">
                                  <input
                                    onChange={() =>
                                      setFilterState((prev) =>
                                        filterState.buyNow
                                          ? { ...prev, buyNow: false }
                                          : { ...prev, buyNow: true },
                                      )
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck8"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="defaultCheck8"
                                  >
                                    Buy Now
                                  </label>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                      {(openMenu ||
                        openMenu1 ||
                        openMenu2 ||
                        openMenu3 ||
                        openMenu4) && (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              type="button"
                              className="accordion-button collapsed"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                            >
                              Categories
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                          >
                            <div className="card-body">
                              {GetNftCollectionCategories &&
                                GetNftCollectionCategories?.map(
                                  (item, index) => (
                                    <div className="form-check" key={index}>
                                      <input
                                        checked={filterState.categories.includes(
                                          item.id,
                                        )}
                                        onChange={() => {
                                          if (
                                            filterState.categories.includes(
                                              item.id,
                                            )
                                          ) {
                                            let remaining =
                                              filterState.categories.filter(
                                                (item2) => item2 !== item.id,
                                              );
                                            setFilterState((prev) => {
                                              return {
                                                ...prev,
                                                categories: remaining,
                                              };
                                            });
                                          } else {
                                            setFilterState((prev) => {
                                              return {
                                                ...prev,
                                                categories: [
                                                  ...filterState.categories,
                                                  item.id,
                                                ],
                                              };
                                            });
                                          }
                                        }}
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id={index}
                                      />
                                      <label
                                        className="form-check-label"
                                        for={index}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  ),
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                      {(openMenu || openMenu1 || openMenu2 || openMenu3) && (
                        <>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                              >
                                Price
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                            >
                              <div className="card-body">
                                <div className="bar-pnl">
                                  <div>
                                    <div className="col-lg-12 col-md-6 col-sm-12">
                                      <div className="my-3"></div>
                                      <h5 className="txt-dark">Min price</h5>
                                      <input
                                        type="number"
                                        className="form-control-custom"
                                        value={minValue}
                                        onChange={(e) => {
                                          setError(false);
                                          setMinValue(e.target.value);
                                          //   minValue > maxValue ? setMinCheck(true): setMinCheck(false)
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {parseFloat(minValue) >
                                    parseFloat(maxValue) && (
                                    <div
                                      className="col-lg-12 col-md-6 col-sm-12"
                                      style={{ color: "red" }}
                                    >
                                      Min value can not be greater then Max
                                      value
                                    </div>
                                  )}

                                  <div>
                                    <div className="col-lg-12 col-md-6 col-sm-12">
                                      <div className="my-3"></div>
                                      <h5 className="txt-dark">Max price</h5>
                                      <input
                                        type="number"
                                        className="form-control-custom"
                                        value={maxValue}
                                        onChange={(e) => {
                                          setError(false);
                                          setMaxValue(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12 text-align-left">
                                    <button
                                      onClick={() => priceHandler()}
                                      className={
                                        error ? "reg-btn grey" : "reg-btn blue"
                                      }
                                      href="javascript:void(0);"
                                    >
                                      Apply{" "}
                                    </button>
                                    {reset && (
                                      <button
                                        onClick={() => {
                                          setError(false);
                                          setMinValue(0);
                                          setMaxValue(0);
                                          setFilterState((prev) => {
                                            return {
                                              ...prev,
                                              min: 0,
                                              max: 0,
                                              sortIndex: 1,
                                            };
                                          });
                                          setResetState(false);
                                        }}
                                        className="reg-btn blue"
                                        style={{
                                          padding: "10px 13px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <i className="fas fa-sync m-l-1"></i>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                              >
                                File
                              </button>
                            </h2>
                            <div
                              id="collapseFour"
                              className="accordion-collapse collapse"
                            >
                              <div className="card-body">
                                <form>
                                  <div className="form-check">
                                    <input
                                      onChange={() =>
                                        setFilterState((prev) =>
                                          filterState.image
                                            ? { ...prev, image: false }
                                            : { ...prev, image: true },
                                        )
                                      }
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="defaultCheck11"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="defaultCheck11"
                                    >
                                      Image
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      onChange={() =>
                                        setFilterState((prev) =>
                                          filterState.video
                                            ? { ...prev, video: false }
                                            : { ...prev, video: true },
                                        )
                                      }
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="defaultCheck12"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="defaultCheck12"
                                    >
                                      Video
                                    </label>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                              >
                                Chains
                              </button>
                            </h2>
                            <div
                              id="collapseFive"
                              className="accordion-collapse collapse"
                            >
                              <div className="card-body">
                                <form>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="defaultCheck13"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="defaultCheck13"
                                    >
                                      ETH
                                    </label>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {openMenu4 && (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              type="button"
                              className="accordion-button collapsed"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOwner"
                            >
                              Ownership
                            </button>
                          </h2>
                          <div
                            id="collapseOwner"
                            className="accordion-collapse collapse"
                          >
                            <div className="card-body">
                              <div className="form-check">
                                <input
                                  checked={!filterState.havingMineNFTS}
                                  onChange={() => {
                                    setFilterState((prev) => {
                                      return { ...prev, havingMineNFTS: false };
                                    });
                                  }}
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="mycollections"
                                />
                                <label
                                  className="form-check-label"
                                  for="mycollections"
                                >
                                  My Collections
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  checked={filterState.havingMineNFTS}
                                  onChange={() => {
                                    setFilterState((prev) => {
                                      return { ...prev, havingMineNFTS: true };
                                    });
                                  }}
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="havingmynfts"
                                />
                                <label
                                  className="form-check-label"
                                  for="havingmynfts"
                                >
                                  Having My NFT's
                                </label>
                              </div>

                              {/* <div className="form-check">
                                <input
                                  checked={true}
                                  onChange={() => {

                                  }}
                                  className="form-check-input" type="checkbox" value="" id="all" />
                                <label className="form-check-label" for="all">
                                  All
                                </label>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                </Accordion>
              </div>
            </div>
          )}

          {/* Side Filter */}
          <div id="collectionsList" className={"tab-inner-container"}>
            {openMenu && (
              <div id="zero2" className="onStep fadeIn">
                <div className="flex-div"></div>
                <MyNft filterNfts={filterState} status={"OnSale"} />
              </div>
            )}
            {openMenu1 && (
              <div id="zero2" className="onStep fadeIn">
                <div className="flex-div"></div>
                <MyNft filterNfts={filterState} status={"Owner"} />
              </div>
            )}
            {openMenu2 && (
              <div id="zero2" className="onStep fadeIn">
                <div className="flex-div"></div>
                <MyNft filterNfts={filterState} status={"Created"} />
              </div>
            )}
            {openMenu6 && (
              <div id="zero2" className="onStep fadeIn">
                <div className="flex-div"></div>
                <MyNft filterNfts={filterState} status={"Sync"} />
              </div>
            )}
            {openMenu3 && (
              <div id="zero3" className="onStep fadeIn">
                <div className="flex-div"></div>
                <MyNft filterNfts={filterState} status={"Favorite"} />
              </div>
            )}
            {openMenu4 && (
              <div id="zero1" className="onStep fadeIn">
                <div className="flex-div"></div>
                <MyCollections filterState={filterState} />
              </div>
            )}
            {openMenu5 && (
              <div id="zero3" className="onStep fadeIn">
                {/* <MyNft />{" "} */}
                <div className="full-div">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <InfiniteScroll
                        dataLength={nftHistory?.length ?? 0}
                        next={showMoreNFTHistory}
                        hasMore={nftHistory?.length >= pageSize}
                        loader={
                          <div className="col-sm-12 d-flex justify-content-center">
                            <ScaleLoader
                              color={WHITE_COLOR}
                              size={LOADER_MEDIUM_TWENTY_SIZE}
                            />
                          </div>
                        }
                      >
                        {nftHistory ? (
                          nftHistory?.map((payload) => (
                            <>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  history.push(
                                    `/usernftdetail/${payload?.nftId}`,
                                  );
                                  localStorage.clear();
                                }}
                                className="activity-pnl"
                              >
                                <div className="left-pnl">
                                  <div className="img-pnl">
                                    {/* <img src={httpUrl + '/' + payload.nftImage} /> */}
                                    {payload?.nftImage.includes(".mp4") ? (
                                      <video
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        src={payload?.nftImage}
                                        controls
                                        currentTime={11.3}
                                      />
                                    ) : (
                                      <img src={payload?.nftImage} />
                                    )}
                                  </div>
                                  <div className="txt-pnl d-flex flex-column justify-content-center align-items-start">
                                    <p>
                                      {payload.nftHistoryType} item #
                                      {payload.nftId} for {payload.nftPrice}{" "}
                                      ETH
                                    </p>
                                    <span>
                                      At{" "}
                                      {moment(payload.createdAt).format(
                                        "Do [of] MMMM, YYYY",
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="right-pnl">
                                  <a className="cart-btn" href="#">
                                    <i className="fa fa-shopping-cart"></i>
                                  </a>
                                </div>
                              </div>
                            </>
                          ))
                        ) : (
                          <></>
                        )}
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="spacer-single"></div>
      <Footer />
    </div>
  );
};
export default MyProfile;
