import http from "../http";
const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;

export default class GetAllNftsByCollectionId {
  static GetAllNftsByCollectionIdApi(
    body,
    buyNow,
    selectedFilter,
    havingMyNFTs,
  ) {
    if (havingMyNFTs) {
      return http.get(
        httpUrl +
          "/api/v1/Nft/GetAllNftsByOtherCollectionId?CollectionId=" +
          body +
          "&PageSize=" +
          http.pageSize,
      );
    } else {
      let buyNoww =
        selectedFilter == "PriceLowToHigh" || selectedFilter == "PriceHighToLow"
          ? true
          : buyNow;
      return http.get(
        httpUrl +
          "/api/v1/Nft/GetAllNftsByCollectionId?CollectionId=" +
          body +
          "&buyNow=" +
          buyNoww +
          // "&buyNow=" + (selectedFilter == "PriceLowToHigh" || selectedFilter == "PriceHightToLow") ? true : buyNow +
          "&TopFilterCollection=" +
          selectedFilter +
          "&PageSize=" +
          http.pageSize,
      );
    }
  }
}
