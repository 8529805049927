import http from "../http";
const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;

export default class GetAllPopularCollections {
  static GetAllPopularCollectionsApi(body, search) {
    return http.post(
      httpUrl + `/api/v1/Nft/GetPopularNFtCollection?search=${search}`,
      body,
    );
  }
}
