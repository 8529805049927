import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer.jsx";
import { createGlobalStyle } from "styled-components";
import GetAllNftsByCollectionIdAction, {
  GetAllNftsByCollectionIdRequest,
} from "../../Redux/Actions/NftActions/GetAllNftsByCollectionIdAction";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, useParams, useLocation } from "react-router";
import {
  PropagateLoader,
  BounceLoader,
  ScaleLoader,
  PulseLoader,
} from "react-spinners";
import { Link } from "react-router-dom";
import GetFavouriteNftAction from "../../Redux/Actions/NftActions/GetFavouriteNftAction";
import RemoveFavouriteNftAction from "../../Redux/Actions/NftActions/RemoveFavouriteNftAction";
import AddFavouriteNftAction from "../../Redux/Actions/NftActions/AddFavouriteNftAction";
import GetNftCollectionByIdWithOutAccountAction from "../../Redux/Actions/NftActions/GetNftCollectionByIdWithOutAccountAction";
import GetAllBlockChainAction from "../../Redux/Actions/Blockchain/GetAllBlockChainAction";
import {
  Accordion,
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Modal,
  SplitButton,
} from "react-bootstrap";
import NftItem from "../Shared/NFT";
import rlf from "../../assets/images/RLF-icon.png";
import rlc from "../../assets/images/RLF-icon.png";

import GetNftMarketAction from "../../Redux/Actions/NftActions/GetNftMarketAction";
import API from "../../Redux/Api";
import CopyToClipboard from "react-copy-to-clipboard";
import http from "../../Redux/Api/http";
import GetMyAllCollectionsAction from "../../Redux/Actions/CollectionAction/GetMyAllCollections";
import {
  LOADER_MEDIUM_TWENTY_SIZE,
  LOADER_SMALL_SIZE,
  WHITE_COLOR,
} from "../../constants";
import InfiniteScroll from "react-infinite-scroll-component";
const GlobalStyles = createGlobalStyle`

`;

const FILTERS = [
  { Check: "All" },
  { PriceLowToHigh: "Price Low To High" },
  { PriceHighToLow: "Price High To Low" },
  { RecentlyListed: "Recently Listed" },
  { HighestLastSale: "Highest Last Sale" },
  { RecentlySold: "Recently Sold" },
  { MostViewed: "Most Viewed" },
  { MostFavorited: "Most Favourited" },
];

const AllNftsByCollectionsId = () => {
  const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;

  const [buyNow, setBuyNow] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Check");
  const dispatch = useDispatch();
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [show, setShow] = useState(false);
  // const  havingMyNFTs = location.state?.havingMyNFTs ?? true
  const havingMyNFTs = false;
  const GetAllNftsByCollectionId = useSelector(
    (state) =>
      state.GetAllNftsByCollectionId?.GetAllNftsByCollectionIdResponse?.data,
  );

  const GetNftCollectionByIdWithOutAccount = useSelector(
    (state) =>
      state?.GetNftCollectionByIdWithOutAccount
        ?.GetNftCollectionByIdWithOutAccountResponse?.data,
  );

  const handleClose = () => {
    setShow(false);
  };
  const searchRef = useRef();
  const [allData, setAllData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterTrigger, setFilterTrigger] = useState(false);

  const [filter, setfilter] = useState([]);
  const [isDeletedLoader, setIsDeletedLoader] = useState(false);
  const [getAllNftsByCollectionIdState, setGetAllNftsByCollectionIdState] =
    useState([]);
  const WalletAddress = useSelector(
    (state) => state.WalletConnction?.WalletResponse?.accounts,
  );
  const [isloading, setIsloading] = useState(true);
  const [collectionStats, setCollectionStats] = useState(false);
  const loadMore = () => {
    setTimeout(() => {
      let collectionsState = getAllNftsByCollectionIdState;
      let start = collectionsState?.length;
      let end = collectionsState?.length + 4;
      setGetAllNftsByCollectionIdState([
        ...collectionsState,
        ...GetAllNftsByCollectionId?.slice(start, end),
      ]);
    }, 1000);
  };

  const getAllCollection = useSelector(
    (state) => state?.GetMyAllCollections?.GetAllMyCollectionsResponse?.data,
  );

  useEffect(() => {
    setGetAllNftsByCollectionIdState(GetAllNftsByCollectionId?.slice(0, 8));
    setAllData(GetAllNftsByCollectionId);

    API.GetCollectionStatsById.GetCollectionStatsByIdApi(id).then(
      (response) => {
        setCollectionStats(response.data.data);
      },
    );
  }, [GetAllNftsByCollectionId, id]);

  const apisCall = () => {
    // dispatch(GetNftMarketAction());
  };

  useEffect(() => {
    dispatch(GetNftCollectionByIdWithOutAccountAction(id));
  }, []);

  useEffect(async () => {
    // await dispatch(GetNftCollectionByIdAction(id));

    setIsloading(true);

    await dispatch(
      GetAllNftsByCollectionIdAction(id, buyNow, selectedFilter, havingMyNFTs),
    )
      .then((res) => {
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        toast.success(`${error?.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    return () => {
      dispatch(GetAllNftsByCollectionIdRequest());
    };
  }, [buyNow, selectedFilter]);

  const handleSearchChange = (e) => {
    const { value } = e.target;

    setfilter(
      allData?.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  const resetFilter = () => {
    setGetAllNftsByCollectionIdState(allData?.slice(0, 8));
    setfilter([]);
    setFilterTrigger(false);

    searchRef.current.value = "";
  };
  const handlerSearchSubmit = (e) => {
    e.preventDefault();
    setFilterTrigger(true);

    setGetAllNftsByCollectionIdState(filter?.slice(0, 8));
    setFilterData(filter);
  };
  const handleDeleteCollection = () => {
    if (getAllNftsByCollectionIdState?.length > 0) {
      toast.error("Please delete the NFTs before deleting the collection");
    } else {
      setShow(true);
    }
  };

  return (
    <div className="gradient-bg-light">
      <GlobalStyles />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />

      <section className="profile-banner">
        <div className="container">
          <div
            style={{
              backgroundImage: `url(${GetNftCollectionByIdWithOutAccount?.bannerImage?.replaceAll(
                "\\",
                "/",
              )})`,
            }}
            className="banner"
          >
            {/* <img src={httpUrl + "/" + GetNftCollectionByIdWithOutAccount?.bannerImage} alt="Banner images" /> */}
          </div>
        </div>
      </section>

      <section className="container d_coll no-top no-bottom">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="collection_logo_header d_profile cntr">
              <div style={{ position: "absolute" }} className=""></div>
              <div className="profile_avatar">
                <div className="d_profile_img">
                  <img
                    src={GetNftCollectionByIdWithOutAccount?.logoImage}
                    alt=""
                    style={{ height: 150, width: 150 }}
                  />
                  {/* <i className="fa fa-check"></i> */}
                </div>

                <div className="profile_name collection-desc">
                  <div className="text-pnl">
                    <h2>{GetNftCollectionByIdWithOutAccount?.name}</h2>

                    {GetNftCollectionByIdWithOutAccount?.isVerifiedCollection && (
                      <p style={{ fontStyle: "italic" }}>Verified Collection</p>
                    )}

                    {!GetNftCollectionByIdWithOutAccount?.collectionSmartContractAddress && (
                      <h6
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(
                            GetNftCollectionByIdWithOutAccount?.address ===
                              WalletAddress
                              ? `/myprofile`
                              : `/profile/${GetNftCollectionByIdWithOutAccount?.address}`,
                          )
                        }
                      >
                        {GetNftCollectionByIdWithOutAccount?.createrName
                          ? "Created by " +
                            GetNftCollectionByIdWithOutAccount?.createrName
                          : "Created by Unnamed"}
                      </h6>
                    )}

                    {GetNftCollectionByIdWithOutAccount?.collectionSmartContractAddress && (
                      <div
                        className="profile-image-holder"
                        style={{ position: "initial" }}
                      >
                        <div className="text-pnl">
                          <p id="wallet">
                            <img
                              src={rlc}
                              style={{
                                display: "inline-block",
                                maxWidth: "20px",
                                marginRight: "4px",
                                marginBottom: "4px",
                              }}
                            />

                            <span className="mr-2" style={{ color: "white" }}>
                              {GetNftCollectionByIdWithOutAccount?.collectionSmartContractAddress.slice(
                                0,
                                6,
                              ) +
                                "..." +
                                GetNftCollectionByIdWithOutAccount?.collectionSmartContractAddress.slice(
                                  -6,
                                )}
                            </span>

                            <CopyToClipboard
                              text={
                                GetNftCollectionByIdWithOutAccount?.collectionSmartContractAddress
                              }
                              onCopy={() => {
                                toast.success("Address copied successfully", {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: false,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }}
                            >
                              <button
                                id="btn_copy"
                                style={{
                                  background:
                                    "linear-gradient(135deg, #3EAC91 0%, #3EAC91 100%)",
                                  color: "white",
                                  border: "none",
                                }}
                                title="Copy Address"
                              >
                                Copy
                              </button>
                            </CopyToClipboard>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="whte-txt"></div>
                  <div className="pt-2"></div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {GetNftCollectionByIdWithOutAccount?.address ==
                      WalletAddress && (
                      <button
                        onClick={() => {
                          history.push(`/addcollection/${id}`);
                        }}
                        className="btn btn-main mr-2"
                      >
                        Update Collection
                      </button>
                    )}

                    {GetNftCollectionByIdWithOutAccount?.address ==
                      WalletAddress &&
                      id && (
                        <button
                          onClick={() => {
                            handleDeleteCollection();
                          }}
                          className="btn btn-main "
                        >
                          Delete Collection
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* modal for delete */}
            <Modal centered show={show} onHide={handleClose}>
              <Modal.Header className=" d-flex justify-content-center align-items-center">
                <h3 className="text-dark text-center">Delete Collection</h3>
              </Modal.Header>
              <Modal.Body className="text-center">
                Do you want to delete this collection?
              </Modal.Body>
              <Modal.Footer>
                <div className="w-100 d-flex justify-content-center align-items-center">
                  <a
                    className="reg-btn w-100 mx-2"
                    onClick={() => {
                      setIsDeletedLoader(true);
                      if (collectionStats?.totalNft > 1) {
                        setTimeout(() => {
                          toast.warn(
                            "Invalid ! You have to delete your nfts first.",
                          );
                          setIsDeletedLoader(false);
                          setShow(false);
                        }, 1000);
                      } else {
                        http
                          .delete(
                            httpUrl +
                              `/api/v1/Nft/DeleteCollection?collectionId=${id}`,
                          )
                          .then((resp) => {
                            toast.success("Collection has been Deleted !");
                            dispatch(GetAllBlockChainAction())
                              .then((blockchainApiData) => {
                                setIsDeletedLoader(false);
                                dispatch(
                                  GetMyAllCollectionsAction({
                                    catgoryId: [],
                                  }),
                                ).then((res) => {
                                  setIsDeletedLoader(false);
                                  setShow(false);
                                });
                              })
                              .catch((error) => {});
                            if (resp.data.isSuccess) {
                              setTimeout(() => {
                                history.push("/myprofile");
                              }, 3000);
                            }
                          })
                          .catch((err) => {
                            setIsDeletedLoader(false);
                          });
                      }
                    }}
                  >
                    {isDeletedLoader ? (
                      <PulseLoader
                        color={WHITE_COLOR}
                        size={LOADER_SMALL_SIZE}
                      />
                    ) : (
                      "Delete"
                    )}
                  </a>
                  <a className="reg-btn w-100" onClick={handleClose}>
                    Close
                  </a>
                </div>
              </Modal.Footer>
            </Modal>

            {/* exit model for delet */}
            <section className="container p-t-0">
              <div className="row">
                <div className="col-md-12 text-center">
                  <ul className="k-list">
                    <li>
                      <h3>{collectionStats?.totalNft}</h3>
                      <span>Items</span>
                    </li>
                    <li>
                      <h3>{collectionStats?.totalOwned}</h3>
                      <span>Owned</span>
                    </li>
                    <li className="active">
                      <h3>{collectionStats?.totalFloorPrice}</h3>
                      <span>floor price</span>
                    </li>
                    <li>
                      <h3>{collectionStats?.totalVolume}</h3>
                      <span>Volume</span>
                    </li>
                  </ul>
                </div>
                <div
                  className="text-center nrml-txt"
                  style={{ maxWidth: "500px", margin: "auto", color: "white" }}
                >
                  {showMore
                    ? GetNftCollectionByIdWithOutAccount?.description
                    : `${GetNftCollectionByIdWithOutAccount?.description?.substring(
                        0,
                        100,
                      )}`}

                  {GetNftCollectionByIdWithOutAccount?.description?.length >
                  100 ? (
                    <span
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        borderRadius: "2px",
                        background:
                          "linear-gradient(135deg, #3EAC91 0%, #3EAC91",
                        color: "white",
                        display: "inline-block",
                      }}
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? " show less" : "show more"}
                    </span>
                  ) : null}
                  {/* <p title={GetNftCollectionByIdWithOutAccount?.description}>{GetNftCollectionByIdWithOutAccount?.description?.length > 45 ? GetNftCollectionByIdWithOutAccount?.description?.substring(0, 45)+'...' : GetNftCollectionByIdWithOutAccount?.description}
                  </p> */}
                </div>
              </div>
            </section>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {GetNftCollectionByIdWithOutAccount?.websiteLink &&
              GetNftCollectionByIdWithOutAccount?.websiteLink != "null" ? (
                <a
                  target="_blank"
                  href={GetNftCollectionByIdWithOutAccount?.websiteLink}
                  style={{
                    cursor: "pointer",
                    color: "#E250E5",
                    marginRight: "30px",
                    fontSize: "15px",
                  }}
                >
                  <i className="fa fa-globe web-color"></i>
                </a>
              ) : (
                <></>
              )}

              {GetNftCollectionByIdWithOutAccount?.discordLink &&
              GetNftCollectionByIdWithOutAccount?.discordLink != "null" ? (
                <a
                  target="_blank"
                  href={GetNftCollectionByIdWithOutAccount?.discordLink}
                  style={{
                    cursor: "pointer",
                    color: "#E250E5",
                    marginRight: "30px",
                    fontSize: "15px",
                  }}
                >
                  <i className="fab fa-discord discord-color"></i>
                </a>
              ) : (
                <></>
              )}
              {/* <a target="_blank" href={userData?.twitterLink} style={{cursor:"pointer",color:"white"}}><i className="fa fa-twitter"></i></a> */}

              {GetNftCollectionByIdWithOutAccount?.twitterLink &&
              GetNftCollectionByIdWithOutAccount?.twitterLink != "null" ? (
                <a
                  target="_blank"
                  href={GetNftCollectionByIdWithOutAccount?.twitterLink}
                  style={{
                    cursor: "pointer",
                    color: "#E250E5",
                    marginRight: "30px",
                    fontSize: "15px",
                  }}
                >
                  <i className="fa fa-twitter twitter-color"></i>
                </a>
              ) : (
                <></>
              )}
              {GetNftCollectionByIdWithOutAccount?.instagramLink &&
              GetNftCollectionByIdWithOutAccount?.instagramLink != "null" ? (
                <a
                  target="_blank"
                  href={GetNftCollectionByIdWithOutAccount?.instagramLink}
                  style={{
                    cursor: "pointer",
                    color: "#E250E5",
                    marginRight: "30px",
                    fontSize: "15px",
                  }}
                >
                  <i className="fa fa-instagram insta-color"></i>
                </a>
              ) : (
                <></>
              )}

              {GetNftCollectionByIdWithOutAccount?.mediumLink &&
              GetNftCollectionByIdWithOutAccount?.mediumLink != "null" ? (
                <a
                  target="_blank"
                  href={GetNftCollectionByIdWithOutAccount?.mediumLink}
                  style={{
                    cursor: "pointer",
                    color: "#E250E5",
                    marginRight: "30px",
                    fontSize: "15px",
                  }}
                >
                  <i className="fa fa-medium medium-color"></i>
                </a>
              ) : (
                <></>
              )}
              {/* <a target="_blank" href={userData?.twitterLink} style={{cursor:"pointer",color:"white"}}><i className="fa fa-twitter"></i></a> */}

              {GetNftCollectionByIdWithOutAccount?.tLink &&
              GetNftCollectionByIdWithOutAccount?.tLink != "null" ? (
                <a
                  target="_blank"
                  href={GetNftCollectionByIdWithOutAccount?.tLink}
                  style={{
                    cursor: "pointer",
                    color: "#E250E5",
                    marginRight: "30px",
                    fontSize: "15px",
                  }}
                >
                  <i className="fa fa-telegram telegram-color"></i>
                </a>
              ) : (
                <></>
              )}
              {/* <a target="_blank" href={userData?.yourSiteLink}  style={{cursor:"pointer",color:"white"}}><i className="fa fa-yourSiteLink"></i></a> */}
            </div>
          </div>
        </div>
      </section>
      <section className="container mb-cntr tab-txt-cntr">
        <div className="row">
          <div className="col-lg-12 col-md-12  col-sm-12" style={{}}>
            <div className="row">
              <div className="items_filter   offset-lg-3  col-lg-6 col-md-6 col-sm-12">
                <form
                  className="row form-dark w-100"
                  id="form_quick_search"
                  name="form_quick_search"
                  onReset={() => {
                    resetFilter();
                  }}
                  onSubmit={handlerSearchSubmit}
                >
                  <div className="col-sm-12 d-flex align-items-start justify-content-center">
                    <input
                      className="form-control-custom"
                      id="name_1"
                      name="name_1"
                      ref={searchRef}
                      placeholder="search item here..."
                      type="text"
                      onChange={(e) => handleSearchChange(e)}
                      style={{ width: "100%", color: "black" }}
                    />
                    <button id="btn-submit">
                      <i
                        className="fa fa-search bg-color-secondary"
                        style={{ height: "42px" }}
                      ></i>
                    </button>
                    {filterTrigger && (
                      <button id="btn-submit" type="reset">
                        <i class="fas fa-sync bg-danger m-l-1"></i>
                      </button>
                    )}
                    <div className="clearfix"></div>
                  </div>
                </form>
              </div>

              {!havingMyNFTs && (
                <div className="col-lg-3 col-md-3  col-sm-12">
                  <select
                    className="form-select form-control custom-select-1"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setSelectedFilter(e.target.value);
                    }}
                    // value={selectedFilter}
                    name="payment_token"
                    style={{
                      color: "black",
                      border: "solid 1px #3d3d3d",
                    }}
                  >
                    {FILTERS.map((item, index) => {
                      return (
                        <option
                          key={index}
                          selected={selectedFilter === Object.keys(item)[0]}
                          value={Object.keys(item)[0]}
                          style={{
                            border: "1px solid #02AAB0",
                            color: "black",
                          }}
                        >
                          {item[Object.keys(item)[0]]}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              {!havingMyNFTs && (
                <div className="col-lg-3 col-xl-3 col-sm-12 col-sm-12">
                  <div className="side-filter-bar">
                    <div className="filter-head-pnl">
                      <h5>Filters</h5>
                      <i className="fa fa-filter"></i>
                    </div>
                    <div className="filter-body-pnl">
                      <Accordion>
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="1"
                            >
                              Status <i className="fa fa-angle-down"></i>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <div style={{ paddingLeft: 20 }}>
                                <input
                                  checked={buyNow}
                                  onChange={() => {
                                    setBuyNow(!buyNow);
                                  }}
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                />
                                <label style={{ color: "white", marginTop: 4 }}>
                                  Listed
                                </label>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-lg-9 col-xl-9 col-sm-12 col-sm-12">
                <div className="row">
                  {isloading ? (
                    <>
                      <div className="col-sm-12 d-flex justify-content-center">
                        <BounceLoader color="white" size="60" />
                      </div>
                    </>
                  ) : (
                    <>
                      {collectionStats?.totalNft == 0 && (
                        <div
                          className="col-sm-12 text-center text-white"
                          style={{ color: "white" }}
                        >
                          No NFT Record Found
                        </div>
                      )}
                      {/* scroller */}
                      <InfiniteScroll
                        dataLength={getAllNftsByCollectionIdState?.length ?? 0}
                        next={loadMore}
                        hasMore={
                          getAllNftsByCollectionIdState?.length <
                            GetAllNftsByCollectionId?.length && !filterTrigger
                        }
                        className="row"
                        loader={
                          <div className="col-sm-12 d-flex justify-content-center">
                            <ScaleLoader color="white" size="20" />
                          </div>
                        }
                      >
                        {getAllNftsByCollectionIdState?.map((item, index) => (
                          <NftItem
                            nft={item}
                            key={index}
                            likeAndDisLikeCallback={apisCall}
                            color={"black"}
                          />
                        ))}
                      </InfiniteScroll>

                      {/*  */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default AllNftsByCollectionsId;
