import { handleActions } from "redux-actions";

const initialState = {
  GetSimpleOfferResponse: {},
};

const defaultData = {
  data: {
    token: null,
    userInfo: {},
  },
  error: null,
  isSuccess: true,
  message: "",
};
const SimpleOffer = handleActions(
  {
    GetSimpleOffer_REQUEST: (state) => ({
      ...state,
      GetSimpleOfferResponse: {
        ...state.defaultData,
      },
    }),
    GetSimpleOffer_SUCCESS: (state, { payload }) => ({
      ...state,
      GetSimpleOfferResponse: payload,
    }),
    GetSimpleOffer_FAIL: (state, { payload }) => ({
      ...state,
      GetSimpleOfferResponse: payload.data,
    }),
    GetSimpleOffer_REQUEST_CLEAR: (state) => ({
      ...state,
      GetSimpleOfferResponse: {
        ...state.defaultData,
      },
    }),
  },
  initialState,
);

export default SimpleOffer;
