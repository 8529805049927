import detectEthereumProvider from "@metamask/detect-provider";
import { ethers, utils } from "ethers";
import localforage from "localforage";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Web3 from "web3";
import { MyProfileRequest } from "../Redux/Actions/Account/MyProfileAction";
import AuthConnectAction, {
  AuthConnectRequest,
} from "../Redux/Actions/AuthActions/AuthConnectAction";
import ValidateSignatureAction, {
  ValidateSignatureRequest,
} from "../Redux/Actions/AuthActions/ValidateSignatureAction";
import { WalletDisconnect } from "../Redux/Actions/WalletActions/WalletAction";
import makeOfferAbi from "./abis/makeOfferAbi";
import {
  WEI_TO_ETHER,
  acceptOfferABI,
  cancelOfferABI,
  gasLimit,
  getOfferABI,
  makeOfferABI,
  totalOfferCountABI,
  NFTTOKENAbi
} from "./abisList/allAbis";
export const signMessage = (message) => async (dispatch) => {
  if (window.ethereum) {
    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    const address = await signer.getAddress();
    return new Promise((resolve, reject) => {
      dispatch(
        ValidateSignatureAction(
          {
            address: address,
            signature: signature,
          },
          // true
        ),
      )
        .then((res) => {
          // toast.success(
          //   `${res.message} you are going to redirect to profile page`,
          //   {
          //     position: "top-right",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //   }
          // );

          // setIsLoading(false);
          resolve(res);
        })
        .catch((err) => {
          // setIsLoading(false);

          // toast.error(`${err.message}`, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          reject(err);
        });
    });
  }
};

export const buyNftMarket = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
    // buy nft on fixed price
    let buyNftOnFixedPriceAbi = [
      {
        inputs: [
          {
            internalType: "address",
            name: "_nftAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_nftId",
            type: "uint256",
          },
        ],
        name: "buyNFT",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
    ];
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();
    const priceVal = Web3.utils.toWei(String(payload.price, "ether"));
    // const priceVal = window.web3.utils.toWei(payload.price, 'ether').toString();
    let d = {
      value: payload.price.toString(),
    };
    let contract = new ethers.Contract(
      payload.market_address,
      buyNftOnFixedPriceAbi,
      signer,
    );

    await contract
      .buyNFT(payload._nftAddress, payload._nftId, {
        value: priceVal,
        gasLimit: 250000,
      })
      .then(async (response) => {
        response
          .wait()
          .then((receipt) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cancelNft = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
    let cancelListingABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "_nftAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_nftId",
            type: "uint256",
          },
        ],
        name: "cancelListing",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();
    let contract = new ethers.Contract(
      payload.marketAddress,
      cancelListingABI,
      signer,
    );
    await contract
      .cancelListing(payload._nftAddress, payload._nftId, {
        gasLimit: 250000,
      })
      .then(async (res) => {
        res
          .wait()
          .then(() => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const openForAuction = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
    let minABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "_approved",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_tokenId",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();
    let contract = new ethers.Contract(payload.nftContractId, minABI, signer);
    await contract
      .approve(payload.contractAddress, payload.tokenId, {
        gasLimit: 250000,
      })
      .then(async (res) => {
        res
          .wait()
          .then((asdas) => {
            let minABI = [
              {
                inputs: [
                  {
                    internalType: "address",
                    name: "nftContractId",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "tokenId",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "price",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "maxPrice",
                    type: "uint256",
                  },
                ],
                name: "openMarketForAuctionType",
                outputs: [],
                stateMutability: "nonpayable",
                type: "function",
              },
            ];
            const provider1 = new ethers.providers.Web3Provider(
              window.ethereum,
            );
            const signer = provider1.getSigner();
            const priceVal = window.web3.utils.toWei(String(payload.price));
            // const orgPercentage = window.web3.utils.toWei(payload.orgnizationPercentage, "ether");
            const maxPrice = window.web3.utils.toWei(String(payload.maxPrice));

            // const priceVal = window.web3.utils.toWei(payload.price, 'ether').toString();
            let contract = new ethers.Contract(
              payload.contractAddress,
              minABI,
              signer,
            );
            contract
              .openMarketForAuctionType(
                payload.nftContractId,
                payload.tokenId,
                priceVal,
                maxPrice,
                {
                  gasLimit: 250000,
                },
              )
              .then(async (response) => {
                response
                  .wait()
                  .then((asd) => {
                    resolve({ response });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const acceptBid = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
    let minABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "nftContractId",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "buyerAccount",
            type: "address",
          },
        ],
        name: "closeMarketForAuctionType",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();
    const priceVal = window.web3.utils.toWei(String(payload.price));
    let contract = new ethers.Contract(payload.contractAddress, minABI, signer);
    await contract
      .closeMarketForAuctionType(
        payload.nftContractAddress,
        payload.tokenId,
        priceVal,
        payload.buyerAddress,
        {
          gasLimit: 250000,
        },
      )
      .then(async (response) => {
        response
          .wait()
          .then((ascdfas) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const approveNft = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
    let minABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "_approved",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_tokenId",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();
    const priceVal = window.web3.utils.toWei(String(payload.price));
    let contract = new ethers.Contract(payload.contractAddress, minABI, signer);
    await contract
      .approve(payload.marketPlaceContract, priceVal, {
        gasLimit: 250000,
      })
      .then(async (response) => {
        response
          .wait()
          .then((asfas) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export async function approveContract(payload, contractAddress, payloadMarket) {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }
  return new Promise(async (resolve, reject) => {
    let minABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "_approved",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_tokenId",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];

    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();
    let contract = new ethers.Contract(contractAddress, minABI, signer);

    await contract
      .approve(payload.approved, payload.tokenId, {
        gasLimit: 250000,
      })
      .then(async (response) => {
        response
          .wait()
          .then((abc) => {
            let minABI = [
              {
                inputs: [
                  {
                    internalType: "address",
                    name: "_nftAddress",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "_nftId",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "_price",
                    type: "uint256",
                  },
                ],
                name: "sellNFT",
                outputs: [
                  {
                    internalType: "string",
                    name: "",
                    type: "string",
                  },
                ],
                stateMutability: "nonpayable",
                type: "function",
              },
            ];

            const provider1 = new ethers.providers.Web3Provider(
              window.ethereum,
            );
            const signer = provider1.getSigner();
            const priceVal = window.web3.utils.toWei(
              payloadMarket._price,
              "ether",
            );
            // const orgPercentage = window.web3.utils.toWei(payloadMarket.orgnizationPercentage, "ether");
            let contract = new ethers.Contract(
              payload.approved,
              minABI,
              signer,
            );

            contract
              .sellNFT(
                payloadMarket._nftAddress,
                payloadMarket._nftId,
                priceVal,
                {
                  gasLimit: 250000,
                },
              )
              .then(async (res) => {
                res
                  .wait()
                  .then((abddc) => {
                    resolve({ res, response });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function mint(payload, contractAddress) {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
    let mintingABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "_to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_tokenId",
            type: "uint256",
          },
          {
            internalType: "uint96",
            name: "_royalty",
            type: "uint96",
          },
          {
            internalType: "string",
            name: "uri",
            type: "string",
          },
        ],
        name: "safeMint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];

    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();

    let contract = new ethers.Contract(contractAddress, NFTTOKENAbi, signer);
    console.log('koei', contractAddress, payload[0].to,
      payload[0].tokenId,
      payload[0].royality,
      payload[0].uri);
    await contract
      .safeMint(
        payload[0].to,
        payload[0].tokenId,
        payload[0].royality,
        payload[0].uri,
        {
          gasLimit: 250000,
        },
      )
      .then((res) => {
        res
          .wait()
          .then(() => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((err) => {
        console.log('err', err);
        reject(err);
      });
  });
}
export async function openMarketForFixed(payload, contractAddress) {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
    let minABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "nftContractId",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
        ],
        name: "openMarketForFixedType",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];

    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();

    let contract = new ethers.Contract(contractAddress, minABI, signer);
    await contract
      .openMarketForFixedType(
        payload.nftContractId,
        payload.tokenId,
        payload.price,
        {
          gasLimit: 250000,
        },
      )
      .then((res) => {
        res
          .wait()
          .then((asfas) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const sendTransection = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }
  return new Promise((res, rej) => {
    localStorage.setItem("confirmDialogOpen", true);
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: payload,
        })
        .then(async (data) => {
          res(data);
        })
        .catch((error) => {
          rej(error);
        });
    }
  });
};

export const sellNFTAmount = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
    let minABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "_from",
            type: "address",
          },
          {
            internalType: "address",
            name: "_to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_tokenId",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    let decimals = window.web3.utils.toBN(18);
    const accounts = await window.web3.eth.getAccounts();

    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();

    let contract = new ethers.Contract(payload.from, minABI, signer);
    await contract
      .transferFrom(accounts[0], payload.to, payload.tokenId, {
        gasLimit: 250000,
      })
      .then((res) => {
        res
          .wait()
          .then((asfas) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkBalance1 = async (tokenAddress, walletAddress) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }
  return new Promise((resolve, reject) => {
    const minABI = [
      {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "balance", type: "uint256" }],
        type: "function",
      },
    ];
    const contract = new window.web3.eth.Contract(minABI, tokenAddress);

    contract.methods
      .balanceOf(walletAddress)
      .call()
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// make offer
export const makeOffer = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }
  return new Promise(async (resolve, reject) => {
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();
    const priceVal = Web3.utils.toWei(String(payload.price, WEI_TO_ETHER));
    let contract = new ethers.Contract(
      payload.marketAddress,
      makeOfferABI,
      signer,
    );
    await contract
      .buyNFT(payload._nftAddress, payload._nftId, {
        value: priceVal,
        gasLimit: gasLimit,
      })
      .then(async (response) => {
        response
          .wait()
          .then((receipt) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get offer listing
export const getOfferListing = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }
  return new Promise(async (resolve, reject) => {
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();
    let contract = new ethers.Contract(
      payload.marketAddress,
      getOfferABI,
      signer,
    );

    await contract
      .getOffers(
        payload._nftAddress,
        payload._nftId,
        payload._page,
        payload._size,
      )
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// get total offer count
export const getTotalOffersCount = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }
  return new Promise(async (resolve, reject) => {
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();
    let contract = new ethers.Contract(
      payload.marketAddress,
      totalOfferCountABI,
      signer,
    );
    await contract
      .getOffersLength(payload._nftAddress, payload._nftId)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Accept Simple Listing Offer
export const acceptSimpleListingOffer = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }
  return new Promise(async (resolve, reject) => {
    let minABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "_approved",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_tokenId",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();
    let contract = new ethers.Contract(payload?._nftAddress, minABI, signer);
    await contract
      .approve(payload.marketAddress, payload._nftId, {
        gasLimit: 250000,
      })
      .then(async (response) => {
        response
          .wait()
          .then(async (abc) => {
            const provider = await detectEthereumProvider();
            if (provider !== window.ethereum) {
              window.web3 = new Web3(provider);
            } else {
              window.web3 = new Web3(window.ethereum);
            }
            let contract = new ethers.Contract(
              payload.marketAddress,
              acceptOfferABI,
              signer,
            );
            await contract
              .selectBuyer(
                payload._nftAddress,
                payload._nftId,
                payload._buyer,
                {
                  gasLimit: gasLimit,
                },
              )
              .then(async (response) => {
                resolve(response);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Cancel Listing Offer
export const cancelListingOffer = (payload) => async (dispatch) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }
  return new Promise(async (resolve, reject) => {
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider1.getSigner();

    let contract = new ethers.Contract(
      payload.marketAddress,
      cancelOfferABI,
      signer,
    );
    await contract
      .cancelOffer(payload._nftAddress, payload._nftId, {
        gasLimit: gasLimit,
      })
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
