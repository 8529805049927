import React, { useEffect, useState, useReducer } from "react";
import ColumnZero from "../components/ColumnZero";
import ColumnZeroTwo from "../components/ColumnZeroTwo";
import ColumnZeroThree from "../components/ColumnZeroThree";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import bar from "../../assets/images/bar.png";
import Footer from "../components/Footer.jsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import defaultImg from "../../assets/images/default.png";
import UserNfts from "./UserNft/UserNfts";
import OnSaleUserNfts from "./UserNft/OnSaleUserNfts";
import UserFavNft from "./UserNft/UserFavNft";
import { useDispatch, useSelector } from "react-redux";
import http from "../../Redux/Api/http";
import { PulseLoader, BounceLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import { createGlobalStyle } from "styled-components";
import bannerimg from "../../assets/images/profile-banner.png";
import rlf from "../../assets/images/RLF-icon.png";
import { useHistory } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import API from "../../Redux/Api";
import MyNfts from "./MyNfts/MyNfts";

const GlobalStyles = createGlobalStyle``;
const initialState = { isDisable: false };
const reducer = (state, action) => {
  switch (action.type) {
    case "clicked":
      return { isDisable: true };
    case "notClicked":
      return { isDisable: false };
  }
};

var arr = [];
const PUBLIC_URL = process.env.PUBLIC_URL;

const UserProfile = function () {
  const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;
  const [userData, setUserData] = useState();
  const [itemsCounter, setItemsCounter] = useState();
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(true);
  const [text, settext] = useState("");
  const [showMore, setShowMore] = useState(false);
  const history = useHistory();
  const [state, disableDispatch] = useReducer(reducer, initialState);
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [reset, setResetState] = useState(false);
  const [error, setError] = useState(false);
  const [allCollections, setAllCollections] = useState();

  const [setter, setSetter] = React.useState([]);
  const [categories, setCategories] = useState();

  const [filterState, setFilterState] = React.useState({
    topFilter: "OnSale",
    walletAddress: window.location.pathname.split("/")[2],
    pageSize: 9,
    currentPage: 1,
    buyNow: false,
    onAuctions: false,
    hasOffers: false,
    image: false,
    video: false,
    categories: [],
    min: 0,
    max: 0,
    sortBy: "desc",
    sortIndex: 1,
    search: "",
  });

  useEffect(() => {
    API.GetNftCollectionCategories.GetNftCollectionCategoriesApi().then(
      (response) => {
        setCategories(response.data.data);
      },
    );
  }, []);

  useEffect(async () => {
    let params = window.location.pathname;
    setAddress(params.split("/")[2]);
    await http
      .get(
        httpUrl + `/api/v1/Account/GetAccount?address=${params.split("/")[2]}`,
      )
      .then((res) => {
        setUserData(res.data.data);
        setLoading(false);
        const text1 = res.data.data?.bio ? res.data.data?.bio?.toString() : "";
        settext(text1);
      })
      .catch((error) => {});
  }, []);
  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const handleBtnClick = () => {
    setFilterState((prev) => {
      return { ...prev, topFilter: "OnSale" };
    });
    setOpenMenu(true);
    setOpenMenu1(false);
    setOpenMenu2(false);
    document.getElementById("Mainbtn").classList.add("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setFilterState((prev) => {
      return { ...prev, topFilter: "Owner" };
    });
    setOpenMenu1(true);
    setOpenMenu2(false);
    setOpenMenu(false);
    document.getElementById("Mainbtn1").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
  };
  const handleBtnClick2 = () => {
    setFilterState((prev) => {
      return { ...prev, topFilter: "Favorite" };
    });
    setOpenMenu2(true);
    setOpenMenu(false);
    setOpenMenu1(false);
    document.getElementById("Mainbtn2").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
  };

  const colFilter = (id) => {
    // setCollectionFilterState((prev, index) => collectionFilterState.categories.some(element => element == id) ? { ...prev, categories: collectionFilterState.categories.filter((item, i) => item != id) } : { ...prev, categories: [...collectionFilterState.categories, id] })
    arr.indexOf(id) === -1 ? arr.push(id) : arr.splice(arr.indexOf(id), 1);
    API.GetAllCollectionFilterApi.GetAllCollectionsByCatgoriesIds({
      catgoryId: arr,
    }).then((response) => {
      setAllCollections(response.data.data);
    });
  };

  const priceHandler = () => {
    if (minValue > maxValue) {
      setError(true);
      return;
    }
    if (maxValue == 0) {
      setError(true);
      return;
    }
    if (minValue && maxValue && minValue <= maxValue) {
      setResetState(true);
      setFilterState((prev) => {
        return {
          ...prev,
          min: parseFloat(minValue),
          max: parseFloat(maxValue),
          sortIndex: 0,
        };
      });
      setError(false);
    }
  };

  return (
    <div className="gradient-bg-light">
      {loading ? (
        <>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div className="col-sm-12 d-flex justify-content-center">
            <BounceLoader color="white" size="60" />
          </div>
        </>
      ) : (
        <>
          <GlobalStyles />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
          <div className="profile-banner">
            <div className="container">
              <div
                style={{
                  backgroundImage: `url(${userData?.profileBannerImage?.replaceAll(
                    "\\",
                    "/",
                  )})`,
                }}
                className="banner"
              >
                {/* <img src={bannerimg} /> */}
                <div className="share-list-pnl">
                  <ul className="share-list-list">
                    <CopyToClipboard
                      text={window.location.href}
                      onCopy={() => {
                        disableDispatch({ type: "clicked" });
                        toast.success("Profile copied successfully", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: true,
                          progress: undefined,
                        });
                        setTimeout(() => {
                          disableDispatch({ type: "notClicked" });
                        }, 5000);
                      }}
                    >
                      <li>
                        <a href="javascript:void(0);">
                          <i class="fa fa-share-alt"></i>
                        </a>
                      </li>
                    </CopyToClipboard>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="profile-image-holder">
            <div className="img-pnl">
              <img
                src={
                  userData?.profileImage ? userData?.profileImage : defaultImg
                }
                alt=""
              />
              <span className="check-span">
                <i class="fa fa-check"></i>
              </span>
            </div>
            <div className="text-pnl">
              <h2>{userData?.username ? userData?.username : "Unnamed"}</h2>
              <p id="wallet">
                {/* <span className="email-span" style={{ wordWrap: 'break-word' }}>{userData?.name} </span><br /> */}
                <img
                  src={rlf}
                  style={{
                    display: "inline-block",
                    maxWidth: "20px",
                    marginRight: "4px",
                    marginBottom: "4px",
                  }}
                />
                {userData?.address}{" "}
                <CopyToClipboard
                  text={userData?.address}
                  onCopy={() => {
                    disableDispatch({ type: "clicked" });
                    toast.success("Address copied successfully", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined,
                    });
                    setTimeout(() => {
                      disableDispatch({ type: "notClicked" });
                    }, 5000);
                  }}
                >
                  <button
                    id="btn_copy"
                    style={{
                      background:
                        "linear-gradient(135deg, #3EAC91 0%, #3EAC91 100%)",
                      color: "white",
                      border: "none",
                    }}
                    title="Copy Address"
                    disabled={state.isDisable}
                  >
                    Copy
                  </button>
                </CopyToClipboard>
              </p>
              <br />

              {text && (
                <p
                  id="wallet"
                  className="email-span"
                  style={{ wordWrap: "break-word", maxWidth: "500px" }}
                >
                  {showMore ? text : `${text?.substring(0, 45)}`}
                  {text?.length > 45 ? (
                    <button
                      id="btn_copy"
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        background:
                          "linear-gradient(135deg, #3EAC91 0%, #3EAC91",
                        color: "white",
                        wordBreak: "break-word",
                      }}
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? " show less" : "show more"}
                    </button>
                  ) : null}
                </p>
              )}

              <div className="user-social-contacts">
                {userData?.instagramLink &&
                userData?.instagramLink != "null" ? (
                  <a target="_blank" href={userData?.instagramLink}>
                    <i className="fa fa-instagram"></i>
                  </a>
                ) : (
                  <></>
                )}
                {userData?.twitterLink && userData?.twitterLink != "null" ? (
                  <a target="_blank" href={userData?.twitterLink}>
                    <i className="fa fa-twitter"></i>
                  </a>
                ) : (
                  <></>
                )}
                {userData?.yourSiteLink && userData?.yourSiteLink != "null" ? (
                  <a target="_blank" href={userData?.yourSiteLink}>
                    <i className="fa fa-link"></i>
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <section className="container p-t-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="items_filter">
                  <ul className=" de_nav">
                    <li id="Mainbtn" className="active">
                      <span onClick={handleBtnClick}>On Sale</span>
                    </li>
                    <li id="Mainbtn1" className="">
                      <span onClick={handleBtnClick1}>NFTs</span>
                    </li>
                    <li id="Mainbtn2" className="">
                      <span onClick={handleBtnClick2}>Liked</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tab-container full-div">
              {/* Side Filter */}
              <div className="side-filter-bar">
                <div className="filter-head-pnl">
                  <h5>Filters</h5>
                  <i className="fa fa-filter"></i>
                </div>
                <div className="filter-body-pnl">
                  <div class="accordion" id="myAccordion">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          type="button"
                          class="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Status
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse">
                        <div class="card-body">
                          <form>
                            <div class="form-check">
                              <input
                                onChange={() =>
                                  setFilterState((prev) =>
                                    filterState.buyNow
                                      ? { ...prev, buyNow: false }
                                      : { ...prev, buyNow: true },
                                  )
                                }
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck8"
                              />
                              <label
                                class="form-check-label"
                                for="defaultCheck8"
                              >
                                Buy Now
                              </label>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          type="button"
                          class="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                        >
                          Categories
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse">
                        <div class="card-body">
                          {categories?.map((item, index) => (
                            <div class="form-check" key={index}>
                              <input
                                checked={filterState.categories.includes(
                                  item.id,
                                )}
                                onChange={() => {
                                  // setcurrentpage(1)
                                  if (
                                    filterState.categories.includes(item.id)
                                  ) {
                                    let remaining =
                                      filterState.categories.filter(
                                        (item2) => item2 !== item.id,
                                      );
                                    setFilterState((prev) => {
                                      return { ...prev, categories: remaining };
                                    });
                                  } else {
                                    setFilterState((prev) => {
                                      return {
                                        ...prev,
                                        categories: [
                                          ...filterState.categories,
                                          item.id,
                                        ],
                                      };
                                    });
                                  }
                                }}
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id={index}
                              />
                              <label class="form-check-label" for={index}>
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            type="button"
                            class="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                          >
                            Price
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                        >
                          <div class="card-body">
                            <div className="bar-pnl">
                              <div>
                                <div className="col-lg-12 col-md-6 col-sm-12">
                                  <div className="my-3"></div>
                                  <h5 className="txt-dark">Min price</h5>
                                  <input
                                    type="number"
                                    className="form-control-custom"
                                    value={minValue}
                                    onChange={(e) => {
                                      setError(false);
                                      setMinValue(e.target.value);
                                      //   minValue > maxValue ? setMinCheck(true): setMinCheck(false)
                                    }}
                                  />
                                </div>
                              </div>
                              {parseFloat(minValue) > parseFloat(maxValue) && (
                                <div
                                  className="col-lg-12 col-md-6 col-sm-12"
                                  style={{ color: "red" }}
                                >
                                  Min value can not be greater then Max value
                                </div>
                              )}

                              <div>
                                <div className="col-lg-12 col-md-6 col-sm-12">
                                  <div className="my-3"></div>
                                  <h5 className="txt-dark">Max price</h5>
                                  <input
                                    type="number"
                                    className="form-control-custom"
                                    value={maxValue}
                                    onChange={(e) => {
                                      setError(false);
                                      setMaxValue(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 text-align-left">
                                <button
                                  onClick={() => priceHandler()}
                                  className={
                                    error ? "reg-btn grey" : "reg-btn blue"
                                  }
                                  href="javascript:void(0);"
                                >
                                  Apply{" "}
                                </button>
                                {reset && (
                                  <button
                                    onClick={() => {
                                      setError(false);
                                      setMinValue(0);
                                      setMaxValue(0);
                                      setFilterState((prev) => {
                                        return {
                                          ...prev,
                                          min: 0,
                                          max: 0,
                                          sortIndex: 1,
                                        };
                                      });
                                      setResetState(false);
                                    }}
                                    className="reg-btn blue"
                                    style={{
                                      padding: "10px 13px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <i class="fas fa-sync m-l-1"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                          <button
                            type="button"
                            class="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                          >
                            File
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          class="accordion-collapse collapse"
                        >
                          <div class="card-body">
                            <form>
                              <div class="form-check">
                                <input
                                  onChange={() =>
                                    setFilterState((prev) =>
                                      filterState.image
                                        ? { ...prev, image: false }
                                        : { ...prev, image: true },
                                    )
                                  }
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="defaultCheck11"
                                />
                                <label
                                  class="form-check-label"
                                  for="defaultCheck11"
                                >
                                  Image
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  onChange={() =>
                                    setFilterState((prev) =>
                                      filterState.video
                                        ? { ...prev, video: false }
                                        : { ...prev, video: true },
                                    )
                                  }
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="defaultCheck12"
                                />
                                <label
                                  class="form-check-label"
                                  for="defaultCheck12"
                                >
                                  Video
                                </label>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFive">
                          <button
                            type="button"
                            class="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                          >
                            Chains
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          class="accordion-collapse collapse"
                        >
                          <div class="card-body">
                            <form>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="defaultCheck13"
                                />
                                <label
                                  class="form-check-label"
                                  for="defaultCheck13"
                                >
                                  ETH
                                </label>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
              {/* Side Filter */}
              <div className="tab-inner-container">
                {openMenu && (
                  <div id="zero1" className="onStep fadeIn">
                    <div className="flex-div">
                      {/* <div>
                        <h1>200 Items</h1>
                      </div> */}
                      {/* <ul className='sort-list'>
                        <li><a href="#">Sort by <i class="fa fa-list"></i></a></li>
                        <li><a href="#">Low To High <i className='fa fa-angle-down'></i></a></li>
                      </ul> */}
                    </div>
                    {/* <OnSaleUserNfts /> */}
                    <MyNfts filterNfts={filterState} status={"OnSale"} />
                  </div>
                )}
                {openMenu1 && (
                  <div id="zero2" className="onStep fadeIn">
                    <div className="flex-div">
                      {/* <div>
                        <h1>200 Items</h1>
                      </div> */}
                      {/* <ul className='sort-list'>
                        <li><a href="#">Sort by <i class="fa fa-list"></i></a></li>
                        <li><a href="#">Low To High <i className='fa fa-angle-down'></i></a></li>
                      </ul> */}
                    </div>
                    {/* <UserNfts /> */}
                    <MyNfts filterNfts={filterState} status={"Created"} />
                  </div>
                )}
                {openMenu2 && (
                  <div id="zero3" className="onStep fadeIn">
                    <div className="flex-div">
                      {/* <ul className='sort-list'>
                        <li><a href="#">Sort by <i class="fa fa-list"></i></a></li>
                        <li><a href="#">Low To High <i className='fa fa-angle-down'></i></a></li>
                      </ul> */}
                    </div>
                    {/* <UserFavNft /> */}
                    <MyNfts filterNfts={filterState} status={"Favorite"} />
                  </div>
                )}
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </div>
  );
};
export default UserProfile;
