import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
export const VerifiedCollection = () => {
  return (
    <div style={{ position: "absolute", right: 20, top: 6, zIndex: 100 }}>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="button-tooltip-2">This is verified collection</Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <span className="ml-1 text-white" {...triggerHandler} ref={ref}>
            <i
              class="fa fa-check"
              aria-hidden="true"
              style={{
                color: "white",
                backgroundColor: "gold",
                padding: "2px 8px",
                borderRadius: 6,
              }}
            />
          </span>
        )}
      </OverlayTrigger>
    </div>
  );
};
