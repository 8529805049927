import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  PAGE_settings_PATH,
  PAGE_Myprofile_PATH,
  PAGE_Home_PATH,
  // PAGE_Market_PATH,
  PAGE_Helpcenter_PATH,
  PAGE_Rangking_PATH,
  PAGE_ItemDetail_PATH,
  PAGE_Author_PATH,
  PAGE_Login_PATH,
  PAGE_News_PATH,
  PAGE_Create_PATH,
  PAGE_Contact_PATH,
  PAGE_UserNftdetails_PATH,
  // PAGE_MyNftDetails_PATH,
  PAGE_AddCollection_PATH,
  // PAGE_Collections_PATH,
  PAGE_Allcollections_PATH,
  PAGE_Explore_PATH,
  PAGE_NftByCollectionsId_PATH,
  PAGE_EditNft_PATH,
  PAGE_AddCollectionEdit_PATH,
  PAGE_FAQ_PATH,
  PAGE_Stake_PATH,
  PAGE_Privacy_PATH,
  PAGE_Blog_PATH,
  PAGE_BlogDetail_PATH,
  PAGE_IMPORT_NFT,
  PAGE_AIRDROP_PATH,
} from "./Routes";

import ScrollToTopBtn from "../components/menu/ScrollToTop";
import Header from "../components/menu/header";
import Home from "../components/pages/home";
import noPage from "../components/pages/noPage";
import Helpcenter from "../components/pages/helpcenter";
import Rangking from "../components/pages/rangking";
import Allcollections from "../components/pages/allcollections";
import Explore from "../components/pages/explore";
import ItemDetail from "../components/pages/ItemDetail";
import UserProfile from "../components/pages/UserProfile";
import ConnectWallet from "../components/pages/ConnectWallet";
import News from "../components/pages/news";
import Create from "../components/pages/CreateNFT";
import Contact from "../components/pages/contact";
import MyProfile from "../components/pages/MyProfile";
import ProfileSettings from "../components/pages/ProfileSettings";
import UserNftDetails from "../components/pages/UserNft/UserNftDetails/UserNftDetails";
import AddCollection from "../components/pages/AddCollection";
import FAQ from "../components/pages/Faq";
import Stake from "../components/pages/AddStake";
import Privacy from "../components/pages/privacy-policy";
import Blog from "../components/pages/blog";
import BlogDetail from "../components/pages/BlogDetail";
import ImportNFT from "../components/pages/import-nft";

import AllNftsByCollectionsId from "../components/pages/AllNftsByCollectionsId";
import Airdrop from "../components/pages/Airdrop";
// export const ScrollTop = ({ children, location }) => {
//   React.useEffect(() => window.scrollTo(0, 0), [location]);
//   return children;
// };

// const PosedRouter = ({ children }) => (
//   <Location>
//     {({ location }) => (
//       <div id="routerhang">
//         <div key={location.key}>
//           <Route location={location}>{children}</Route>
//         </div>
//       </div>
//     )}
//   </Location>
//);
function Router() {
  var isUserLogedIn = false;

  const User = useSelector((state) => state.Login);

  if (
    User.authResponse &&
    User.authResponse.data &&
    User.authResponse?.data?.token
  ) {
    isUserLogedIn = true;
  } else {
    isUserLogedIn = false;
  }

  function PrivateRoute({ component: Component, authed, ...rest }) {
    const User = useSelector((state) => state.Login);
    return (
      <Route
        exact
        {...rest}
        render={(props) =>
          User.authResponse &&
          User.authResponse.data &&
          User.authResponse?.data?.token ? (
            <Component {...props} />
          ) : (
            <>
              <Redirect to={"/connectwallet"} />
            </>
          )
        }
      />
    );
  }

  return (
    <>
      <ScrollToTopBtn />
      <Header />
      <Switch>
        <Route
          exact
          path={PAGE_Home_PATH}
          component={Home}
          key={PAGE_Home_PATH}
        />

        <Route
          authed={isUserLogedIn}
          exact
          path={PAGE_Allcollections_PATH}
          component={Allcollections}
          key={PAGE_Allcollections_PATH}
        />
        <Route
          authed={isUserLogedIn}
          exact
          path={PAGE_Explore_PATH}
          component={Explore}
          key={PAGE_Explore_PATH}
        />
        <PrivateRoute
          exact
          path={PAGE_AddCollection_PATH}
          component={AddCollection}
          key={PAGE_AddCollection_PATH}
        />

        <PrivateRoute
          exact
          path={PAGE_AddCollectionEdit_PATH}
          component={AddCollection}
          key={PAGE_AddCollectionEdit_PATH}
        />

        <Route
          authed={isUserLogedIn}
          exact
          path={PAGE_NftByCollectionsId_PATH}
          component={AllNftsByCollectionsId}
          key={PAGE_NftByCollectionsId_PATH}
        />
        <Route
          exact
          path={PAGE_UserNftdetails_PATH}
          component={UserNftDetails}
          key={PAGE_UserNftdetails_PATH}
        />
        <Route
          exact
          path={PAGE_Author_PATH}
          component={UserProfile}
          key={PAGE_Author_PATH}
        />

        <Route
          exact
          path={PAGE_Login_PATH}
          component={ConnectWallet}
          key={PAGE_Login_PATH}
        />

        <PrivateRoute
          authed={isUserLogedIn}
          exact
          path={PAGE_Create_PATH}
          component={Create}
          key={PAGE_Create_PATH}
        />
        <PrivateRoute
          authed={isUserLogedIn}
          exact
          path={PAGE_AIRDROP_PATH}
          component={Airdrop}
          key={PAGE_AIRDROP_PATH}
        />

        <PrivateRoute
          authed={isUserLogedIn}
          exact
          path={PAGE_EditNft_PATH}
          component={Create}
          key={PAGE_EditNft_PATH}
        />

        <PrivateRoute
          authed={isUserLogedIn}
          exact
          path={PAGE_IMPORT_NFT}
          component={ImportNFT}
          key={PAGE_IMPORT_NFT}
        />
        <PrivateRoute
          authed={isUserLogedIn}
          exact
          path={PAGE_Myprofile_PATH}
          component={MyProfile}
          key={PAGE_Myprofile_PATH}
        />
        <PrivateRoute
          authed={isUserLogedIn}
          exact
          path={PAGE_settings_PATH}
          component={ProfileSettings}
          key={PAGE_settings_PATH}
        />
        <Route path="*" component={noPage} key="*" />
      </Switch>
    </>
  );
}

export default Router;
