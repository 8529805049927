import http from "../http";
const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;

export default class GetTodayNfts {
  static GetTodayNftsApi(body) {
    return http.get(
      httpUrl + "/api/v1/Nft/GetTodayNfts?PageSize=9&CurrentPage=1",
    );
  }
}
