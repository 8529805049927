import React, { useEffect, useState } from "react";
import API from "../../../../Redux/Api";
import http from "../../../../Redux/Api/http";
import NftItem from "../../../Shared/NFT";
const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;
function SyncNFT(filterNfts) {
  const [nfts, Setnfts] = useState([]);

  // useEffect( () => {
  //     // setcurrentpage(2)
  //     // filterNfts.currentPage=1
  //     API.GetNftsFilter.GetNftsFilterApi().then((response) => {
  //       Setnfts(response.data.data);
  //     //   setIsloading(false)
  //     //   settotalItems(response.data.totalItems)
  //     })
  //   }, [filterNfts]);
  useEffect(async () => {
    // await http
    //               .post(
    //                 httpUrl +
    //                 `/api/v1/Nft/AddSyncNft`).then(()=>{}).catch(()=>{})
  }, []);

  const apisCall = () => {
    // dispatch(GetNftMarketAction());
  };
  return (
    <div>
      <div className="row">
        {nfts?.map((nft, index) => (
          <>
            {
              <div
                key={index}
                className="d-item col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 cursor-pointor"
              >
                <NftItem
                  nft={nft}
                  key={index}
                  likeAndDisLikeCallback={apisCall}
                />
              </div>
            }
          </>
        ))}
      </div>
    </div>
  );
}

export default SyncNFT;
