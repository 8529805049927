import http from "../http";
const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;

export default class GetNftHistoryByAccount {
  static GetNftHistoryByAccountApi(currentPage, pageSize) {
    return http.get(
      httpUrl +
        `/api/v1/Nft/NftHistoryActivityByAccount?currentPage=${currentPage}&pageSize=${pageSize}`,
    );
  }
}
