import { createAction, createActions } from "redux-actions";
import Api from "../../Api";

export const GetSimpleOfferRequest = createAction("GetSimpleOffer_REQUEST");
export const GetSimpleOfferSuccess = createAction("GetSimpleOffer_SUCCESS");
export const GetSimpleOfferFail = createAction("GetSimpleOffer_FAIL");

const SimpleOfferAction = (body) => (dispatch) => {
  dispatch(GetSimpleOfferRequest());
  return new Promise((resolve) => {
    return Api.SimpleListingOffer.SimpleListingOfferApi(body)
      .then(({ data }) => {
        dispatch(GetSimpleOfferSuccess(data));
        resolve(data);
      })
      .catch((error) => {
        dispatch(GetSimpleOfferFail(error));
        resolve();
      });
  });
};

export default SimpleOfferAction;
