import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import social1 from "../../assets/images/social-icon-1.png";
import social2 from "../../assets/images/telegram.png";
import social3 from "../../assets/images/social-icon-3.png";
import aroow from "../../assets/images/send-arrow.png";
import footerlogo from "../../assets/images/footer-logo.png";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

import { useHistory } from "react-router";
import { NETWORK_NAME } from "../../constants";
const getCurruntYear = () => {
  return new Date().getFullYear();
};
const Footer = () => {
  const httpUrl = process.env.REACT_APP_DEVELOPMENT_URL;

  const history = useHistory();
  const [email, setEmail] = useState();
  const [error, setError] = useState();

  function validEmail(e) {
    var filter =
      /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(e).search(filter) != -1;
  }

  const emailHandler = (e) => {
    if (e.target.value.length == 0 || !e.target.value || e.target.value == "") {
      setError(false);
    } else if (validEmail(e.target.value)) {
      setEmail(e.target.value);
      setError(false);
    } else {
      setEmail();
      setError(true);
    }
  };

  const subscribeUs = async () => {
    await axios
      .post(httpUrl + `/api/v1/Nft/AddSubcribeUS?email=${email}`)
      .then(async (resp) => {
        toast.success("Subscribed!", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <Container fluid className="footer">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" className="text-center">
                <img className="footer-logo" src={footerlogo} />
                <ul className="footer-list">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/explore">Marketplace</a>
                  </li>
                  <li>
                    <a href="/explore">Explore</a>
                  </li>
                  <li>
                    <a href="/allcollections">Collections</a>
                  </li>
                  <li>
                    <a href="/createnft">Create NFT </a>
                  </li>
                  <li>
                    <a href="/myprofile">My Profile</a>
                  </li>
                  <li>
                    <a href="/explore">NFTS</a>
                  </li>
                </ul>

                <ul className="socail-media-list">
                  <li>
                    <a target="_blank" href="">
                      <img src={social1} alt="twitter" />
                    </a>
                  </li>
                  {/* <li><a target="_blank" href="javascript:void(0);"><img src={social2} alt="Media" /></a></li> */}
                  <li>
                    <a
                      target="_blank"
                      href="https://discord.gg/7MRKhjHqMs"
                    >
                      <img src={social3} alt="Social Media" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://t.me/buildyourstories"
                    >
                      <img style={{width:21, height:21}} src={social2} alt="Social Media" />
                    </a>
                  </li>
                </ul>
                <p>Copyright ©All Rights Reserved.</p>
              </Col>
              <Col xl={{ span: 4, offset: 4 }} lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }} className="text-center">
                <h6>Subscribe Us</h6>
                <div className="subscribe-pnl">
                  <input
                    onChange={(e) => emailHandler(e)}
                    autoComplete="off"
                    className="form-control"
                    placeholder="Info@yourgmail.com"
                  />
                  <button disabled={error} onClick={(e) => subscribeUs()}>
                    <img src={aroow} />
                  </button>
                </div>
                {error && (
                  <span style={{ color: "red" }}>Email must be valid.</span>
                )}
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
};
export default Footer;
