import { Link } from "@reach/router";
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import heroimage from "../../assets/images/hero-image.png";
const LandingHeader = () => {
  const [index, setIndex] = useState(0);
  const words = ["dreams", "stories"];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Swap every 2 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [words.length]);

  return (
    <>
      <Container fluid className="landing-header">
        <Container>
          <Row>
            <Col xl="12">
              <div className="landing-content-container">
                <div className="full-div text-pnl">
                  <span className="drop-span"></span>
                  <span className="drop-span-2"></span>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                    <h1>Mint your</h1>
                    <span className="swap-container">
                      {words.map((word, i) => (
                        <h1
                          key={word}
                          className={`word ${i === index ? "visible" : "hidden"}`}
                        >
                          {word}
                        </h1>
                      ))}
                    </span>
                  </div>

                  <p>Transform Dreams Into Assets, Discover Timeless Collections</p>
                  <Link to="/explore" className="reg-btn">
                    Discover{" "}
                  </Link>
                </div>
                <div className="img-pnl">
                  <img src={heroimage} className="big-img" alt="image" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default LandingHeader;
