export const NUMBER_OF_NFTS_TO_SHOW = 8;
export const LOADER_SMALL_SIZE = "11";
export const LOADER_MEDIUM_SIZE = "15";
export const LOADER_MEDIUM_TWENTY_SIZE = "20";

export const WHITE_COLOR = "white";
export const BLACK_COLOR = "black";
export const RED_COLOR = "red";

export const REGEX_CREATE_NFT = /^[0-9]{0,2}?$|^-?(100)(.[0]{1,2})?$/;
export const REGEX_ROYALTY = /^(?![\s-])[\w\s-]*$/;
export const onlyAlphabetsWithSpace = /^[A-Za-z]*([A-Za-z]+[ ]?[A-Za-z]*)*$/;

export const CHAIN_ID = '0x14A34';
export const CHAIN_ID_COMPARE = 84532;
export const NETWORK_NAME = 'Base Chain Testnet';

export const USER_NFT_DETAILS_TABS = {
  LISTING_OFFER_TAB: 0,
  ACTIVITY_HISTORY: 1,
};

export const IMAGE_NOT_FOUND =
  "https://cdn-icons-png.flaticon.com/512/456/456212.png";

export const MP4 = ".mp4";
