export const PAGE_Home_PATH = "/";
// export const PAGE_Market_PATH = "/marketplace";
export const PAGE_Helpcenter_PATH = "/helpcenter";
export const PAGE_Rangking_PATH = "/rangking";
// export const PAGE_Collections_PATH = "/collections";
export const PAGE_Allcollections_PATH = "/allcollections";
export const PAGE_Explore_PATH = "/explore";
export const PAGE_ItemDetail_PATH = "/itemdetail";
export const PAGE_Author_PATH = "/profile/:id";
export const PAGE_Wallet_PATH = "/wallet";
export const PAGE_Login_PATH = "/connectwallet";

export const PAGE_Myprofile_PATH = "/myprofile";
export const PAGE_News_PATH = "/news";
export const PAGE_AIRDROP_PATH = "/airdrop";
export const PAGE_Create_PATH = "/createnft";
export const PAGE_EditNft_PATH = "/createnft/:walletAddress/:id/:accountId";
// export const PAGE_Activity_PATH = "/activity";
export const PAGE_Contact_PATH = "/contact";
export const PAGE_settings_PATH = "/settings";
// export const PAGE_MyNftDetails_PATH = "/mynftdetails/:id/:accountId";
export const PAGE_NftByCollectionsId_PATH = "/nftsbycollections/:id";
export const PAGE_UserNftdetails_PATH = "/usernftdetail/:id";
export const PAGE_AddCollection_PATH = "/addcollection";
export const PAGE_AddCollectionEdit_PATH = "/addcollection/:id";
export const PAGE_Stake_PATH = "/addstake";
// export const PAGE_AddCollectionEdit_PATH = "/mycollections/:id";
export const PAGE_FAQ_PATH = "/faq";
export const PAGE_ADD_STAKE_PATH = "/staking-vault";
export const PAGE_Privacy_PATH = "/Privacy";
export const PAGE_Blog_PATH = "/blog";
export const PAGE_BlogDetail_PATH = "/BlogDetail";
// export const PAGE_Terms_PATH="/Terms"
export const PAGE_IMPORT_NFT = "/import-nft";
